import { DataStore } from "aws-amplify";
import { Container, H1, View } from "native-base";
import React, { useEffect, useState } from "react";
import { ImageBackground } from "react-native";
import splashImage from "../../assets/images/splash.png";
import EditableText from "../components/base/EditableText";
import { SafeScrollView } from "../components/base/SafeScrollView";
import { Profile } from "../models";
import useTheme from "../util/useTheme";
import useUserInfo from "../util/useUserInfo";
import FadeInView from "react-native-fade-in-view";

interface Props {
  onComplete?(): void;
}

export default function Welcome(props: Props) {
  const [profile, setProfile] = useState<Profile>();
  const [userInfo] = useUserInfo();
  const theme = useTheme();

  async function fetchData() {
    try {
      const profile = await DataStore.query(Profile);
      console.info(`Loading profile - found ${profile.length}`);
      setProfile(
        profile.length >= 1
          ? profile[0]
          : new Profile({ userID: userInfo.userId })
      );
    } catch (err) {
      console.log("error fetching profile", err);
    }
  }

  async function setName(name: string) {
    if (profile) {
      await DataStore.save(
        Profile.copyOf(profile, (u) => {
          u.name = name;
        })
      );
      if (props.onComplete) props.onComplete();
    }
  }

  useEffect(() => {
    if (userInfo && !profile) fetchData();
  }, [userInfo, profile]);

  return (
    <Container>
      <ImageBackground
        source={splashImage}
        resizeMode="cover"
        resizeMethod="scale"
        style={{ flex: 1 }}
      >
        <FadeInView style={{ flex: 1 }} duration={1000}>
          <SafeScrollView
            style={{ backgroundColor: "#ffffffaa", height: "100%" }}
          >
            <View
              style={{ paddingTop: 400, paddingLeft: 40, paddingRight: 40 }}
            >
              <H1>Welcome!</H1>
              <EditableText
                text={profile?.name}
                onChangeText={(t) => setName(t)}
                placeholder="Enter your name"
              />
            </View>
          </SafeScrollView>
        </FadeInView>
      </ImageBackground>
    </Container>
  );
}
