import React, { useEffect, useState } from 'react';
import { Col, Icon, Row, Text, View } from "native-base";
import { WorkoutSet } from '../../../models';
import EditableText from '../../base/EditableText';
import useTheme from '../../../util/useTheme';
import { DataStore } from 'aws-amplify';

interface WorkoutSetParams {
  setId: string;
}

export function WorkoutSetGroup({setId} : WorkoutSetParams) {
  const theme = useTheme();
  const [set, setSet] = useState<WorkoutSet>();

  async function fetchData() {
    const set = await DataStore.query(WorkoutSet, setId);
    if (set) {
      setSet(set);
    }
  }

  useEffect(() => {
    if (setId) fetchData();
  }, [setId]);

  async function onChangeName(newName: string) {
    if (set) setSet(await DataStore.save(WorkoutSet.copyOf(set, u => {u.name = newName})));
  }

  return (
    <View style={{flex: 1, paddingLeft: 18, paddingTop: 10}}>
      <EditableText onChangeText={onChangeName} text={set?.name} textStyle={{fontFamily: theme.BoldFont}}/>
    </View>
  );
}
