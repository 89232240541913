import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import Workouts from '../screens/Workouts';
import WorkoutDetail from '../screens/WorkoutDetail';
import { WorkoutsParamList } from '../../types';

import StartWorkout from '../screens/StartWorkout';
import AddExercise from '../screens/AddExercise';
import { Container } from 'native-base';

const WorkoutsStack = createStackNavigator<WorkoutsParamList>();

export default function WorkoutsNavigator() {
  return (
    <WorkoutsStack.Navigator>
        <WorkoutsStack.Screen
          name="Workouts"
          component={Workouts}
        />
        <WorkoutsStack.Screen
          name="StartWorkout"
          component={StartWorkout}
        />

        <WorkoutsStack.Screen
          name="WorkoutDetail"
          component={WorkoutDetail}
        />

        <WorkoutsStack.Screen
          name="AddExercise"
          component={AddExercise}
        />
    </WorkoutsStack.Navigator>
  );
}