import { Content, Form, Textarea, Button, Text } from "native-base";
import React, { useEffect, useState } from "react";
import { Dimensions } from "react-native";
import { Exercise } from "../../models";

interface Params {
  exercise: Exercise;
  setExercise(exercise: Exercise): any;
}

export default function ({ exercise, setExercise }: Params) {
  function addInstruction() {
    const newExercise = Exercise.copyOf(exercise, (u) => {
      u.instructions = [...u.instructions||[], ''];
    });
    setExercise(newExercise);
  }

  function updateInstruction(index: number, value: string) {
    const newExercise = Exercise.copyOf(exercise, (u) => {
      u.instructions = [...u.instructions||[]];
      u.instructions[index] = value;
    });
    setExercise(newExercise);
  }

  return (
    <Content>
      <Form>
        {exercise.instructions?.map((instruction, index) => (
          <Textarea
            bordered
            rowSpan={3}
            onChangeText={(value) => {
              updateInstruction(index, value);
            }}
            placeholder="Instruction"
            value={instruction}
          />
        ))}
      </Form>
      <Button onPress={addInstruction}>
        <Text>Add Instruction</Text>
      </Button>
    </Content>
  );
}
