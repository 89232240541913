import { Button, Content, Icon, Right, Text, Textarea } from "native-base";
import React, { useState } from "react";
import { TextInputProps } from "react-native";

interface Params extends TextInputProps{
  text: string | undefined;
}

export default function EditableTextarea({ text }: Params) {
  const [editMode, setEditMode] = useState(false);

  return (
    <Content>
      {editMode ? (
        <Content style={{borderWidth: 1, borderRadius: 3}}>
          <Textarea bordered={false} rowSpan={5} value={text} />
          <Right>
            <Button>
              <Icon type="AntDesign" name="check" />
            </Button>
            <Button>
              <Icon type="MaterialCommunityIcons" name="cancel" />
            </Button>

          </Right>

        </Content>
      ) : (
        <Text onPress={() => {setEditMode(true)}}>{text}</Text>
      )}
    </Content>
  );
}
