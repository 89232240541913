import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import * as React from 'react';

import useColorScheme from '../../hooks/useColorScheme';
import { BottomTabParamList } from '../../types';

import WorkoutsNavigator from './WorkoutsNavigator';
import ProfileNavigator from './ProfileNavigator';
import TrainerNavigator from './TrainerNavigator';
import ExerciseNavigator from './ExerciseNavigator';
import { Button, Footer, FooterTab, Icon, Text, View } from 'native-base';
import { TouchableOpacity } from 'react-native-gesture-handler';

const BottomTab = createBottomTabNavigator<BottomTabParamList>();

function MyTabBar({ state, descriptors, navigation }) {
  const focusedOptions = descriptors[state.routes[state.index].key].options;

  if (focusedOptions.tabBarVisible === false) {
    return null;
  }

  return (
    <Footer>
      <FooterTab>
      {state.routes.map((route, index) => {
        const { options } = descriptors[route.key];
        const label =
          options.tabBarLabel !== undefined
            ? options.tabBarLabel
            : options.title !== undefined
            ? options.title
            : route.name;

        const isFocused = state.index === index;

        const onPress = () => {
          const event = navigation.emit({
            type: 'tabPress',
            target: route.key,
            canPreventDefault: true,
          });

          if (!isFocused && !event.defaultPrevented) {
            navigation.navigate(route.name);
          }
        };

        const onLongPress = () => {
          navigation.emit({
            type: 'tabLongPress',
            target: route.key,
          });
        };

        return (
          <Button
            key={index}
            vertical
            active={isFocused}
            accessibilityLabel={options.tabBarAccessibilityLabel}
            testID={options.tabBarTestID}
            onPress={onPress}
            onLongPress={onLongPress}
            style={{ flex: 1 }}
          >
            {options.tabBarIcon('#ffffff')}
            <Text>
              {label}
            </Text>
          </Button>
        );
      })}
      </FooterTab>
    </Footer>
  );
}


export default function MainNavigator() {
  const colorScheme = useColorScheme();

  return (
    <BottomTab.Navigator initialRouteName="TrainerStack" tabBar={props => <MyTabBar {...props}/>}>
      <BottomTab.Screen
        name="TrainerStack"
        component={TrainerNavigator}
        options={{
          tabBarLabel: "Explore",
          tabBarIcon: ({ color }) => <Icon type="MaterialIcons" name="explore"/>,
        }}
      />
      <BottomTab.Screen
        name="WorkoutsStack"
        component={WorkoutsNavigator}
        options={{
          tabBarLabel: "Workouts",
          tabBarIcon: ({ color }) => <Icon type="AntDesign" name="profile"/>,
        }}
      />
      <BottomTab.Screen
        name="ExerciseStack"
        component={ExerciseNavigator}
        options={{
          tabBarLabel: "Exercises",
          tabBarIcon: ({ color }) => <Icon type="MaterialCommunityIcons" name="dumbbell"/>,
        }}
      />
      <BottomTab.Screen
        name="ProfileStack"
        component={ProfileNavigator}
        options={{
          tabBarLabel: "Profile",
          tabBarIcon: ({ color }) => <Icon type="AntDesign" name="setting"/>,
        }}
      />
    </BottomTab.Navigator>
  );
}

// You can explore the built-in icon families and icons on the web at:
// https://icons.expo.fyi/



