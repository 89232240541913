import useFocused from "../util/useFocused";
import { StackScreenProps } from "@react-navigation/stack";
import { DataStore } from "aws-amplify";
import {
  Body,
  Button,
  Container,
  Content,
  Icon,
  Left,
  List,
  ListItem,
  Right,
  Spinner,
  Text,
  Title,
} from "native-base";
import React, { useEffect, useState } from "react";
import { WorkoutsParamList } from "../../types";
import { Workout } from "../models";
import useTheme from "../util/useTheme";
import Header from "../components/base/ForceHeader";
import ForceSpinner from "../components/base/ForceSpinner";
import { FlatList } from "react-native-gesture-handler";
import { RefreshControl } from "react-native";

export default function Workouts({
  navigation,
}: StackScreenProps<WorkoutsParamList, "Workouts">) {
  const [workouts, setWorkouts] = useState(new Array<Workout>());
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const [refreshing, setRefreshing] = React.useState(false);

  const onRefresh = React.useCallback(() => {
    fetchData();
  }, []);

  useFocused(() => fetchData());

  React.useLayoutEffect(() => {
    navigation.setOptions({
      header: () => (
        <Header noLeft>
          <Left/>
          <Body>
            <Title>Workouts</Title>
          </Body>
          <Right>
            <Button
              transparent
              onPress={() => navigation.navigate("StartWorkout")}
            >
              <Icon type="AntDesign" name="plus" />
            </Button>
          </Right>
        </Header>
      ),
    });
  });

  async function fetchData() {
    try {
      // setLoading(true);
      const workouts = await DataStore.query(Workout);
      setWorkouts(workouts);
      // setLoading(false);
    } catch (err) {
      console.log("error fetching workouts", err);
    }
  }

  return (
    <Container>
      <FlatList
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
        data={workouts}
        renderItem={({item:workout}) => (<ListItem
          key={workout.id}
          onPress={() =>
            navigation.navigate("WorkoutDetail", { id: workout.id })
          }
        >
          <Body>
            <Text style={{fontFamily: theme.Fonts[theme.Fonts.Oswald_700Bold]}}>{workout.name}</Text>
            <Text note>{workout.description}</Text>
          </Body>
        </ListItem>)}
        ListEmptyComponent={(<ListItem><Body><Text>Get started by clicking the plus or going to Explore and finding a workout.</Text></Body></ListItem>)}
      />
    </Container>
  );
}
