/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import React from "react";
import { View } from "react-native";
import { Auth, I18n, Logger } from "aws-amplify";
import AuthPiece, { IAuthPieceProps, IAuthPieceState } from "./AuthPiece";
import ForceButton from "../components/base/ForceButton";
import FormField from "../components/base/FormField";
import { Body, Button, Container, Content, Footer, Header, Left, Text } from "native-base";
import LinkButton from "../components/base/LinkButton";
import BackButton from "../components/base/BackButton";

const logger = new Logger("ForgotPassword");

interface IForgotPasswordProps extends IAuthPieceProps {}

interface IForgotPasswordState extends IAuthPieceState {
  code?: string;
  delivery?: any;
  password?: string;
}

export default class ForgotPassword extends AuthPiece<
  IForgotPasswordProps,
  IForgotPasswordState
> {
  constructor(props: IForgotPasswordProps) {
    super(props);

    this._validAuthStates = ["forgotPassword"];
    this.state = { delivery: null };

    this.send = this.send.bind(this);
    this.submit = this.submit.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const username = props.authData;

    if (username && !state.username) {
      return { username };
    }

    return null;
  }

  send() {
    const username = this.getUsernameFromInput();
    if (!username) {
      this.error("Username cannot be empty");
      return;
    }
    Auth.forgotPassword(username)
      .then((data) => {
        logger.debug(data);
        this.setState({ delivery: data.CodeDeliveryDetails });
      })
      .catch((err) => this.error(err));
  }

  submit() {
    const { code, password } = this.state;
    const username = this.getUsernameFromInput();
    Auth.forgotPasswordSubmit(username, code, password)
      .then((data) => {
        logger.debug(data);
        this.changeState("signIn");
      })
      .catch((err) => this.error(err));
  }

  forgotBody() {
    return (
      <View>
        {this.renderUsernameField()}
        <ForceButton
          text={I18n.get("Send").toUpperCase()}
          onPress={this.send}
          disabled={!this.getUsernameFromInput()}
        />
      </View>
    );
  }

  submitBody() {
    return (
      <Content>
        <FormField
          onChangeText={(text) => this.setState({ code: text })}
          label={I18n.get("Confirmation Code")}
          placeholder={I18n.get("Enter your confirmation code")}
          required={true}
          value={this.state.code}
        />
        <FormField
          onChangeText={(text) => this.setState({ password: text })}
          label={I18n.get("Password")}
          placeholder={I18n.get("Enter your new password")}
          secureTextEntry={true}
          required={true}
          value={this.state.password}
        />
        <ForceButton
          text={I18n.get("Submit")}
          onPress={this.submit}
          disabled={!(this.state.code && this.state.password)}
        />
      </Content>
    );
  }

  showComponent() {
    return (
      <Container>
        <Header>
          <Left><BackButton onPress={() => this.changeState("signIn")}/></Left>
          <Body><Text>{I18n.get("Reset your password")}</Text></Body>
        </Header>
        <Content>
          {!this.state.delivery && this.forgotBody()}
          {this.state.delivery && this.submitBody()}
        </Content>
      </Container>
    );
  }
}
