import * as React from 'react';

import { createStackNavigator } from "@react-navigation/stack";
import ProfileScreen from "../screens/Profile";
import { TabTwoParamList } from "../../types";
import AdminScreen from '../screens/Admin';
import SystemInfoScreen from '../screens/SystemInfo';

const ProfileStack = createStackNavigator<TabTwoParamList>();

export default function ProfileNavigator() {
  return (
    <ProfileStack.Navigator>
      <ProfileStack.Screen
        name="Profile"
        component={ProfileScreen}
      />
      <ProfileStack.Screen
        name="ProfileDetails"
        component={ProfileScreen}
      />
      <ProfileStack.Screen
        name="Admin"
        component={AdminScreen}
      />
      <ProfileStack.Screen
        name="SystemInfo"
        component={SystemInfoScreen}
      />
    </ProfileStack.Navigator>
  );
}
