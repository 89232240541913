import React, { useState } from "react";

import {
  Container,
  Text,
  Button,
  Input,
  Header,
  Left,
  Body,
  Right,
  Title,
  Icon,
  Form,
  Item,
  Label,
  Footer,
  Content,
  DatePicker,
} from "native-base";

import { StackScreenProps } from "@react-navigation/stack";
import { WorkoutsParamList } from "../../types";
import { DataStore } from "aws-amplify";
import { Workout } from "../models";

import moment from 'moment';
import ForceHeader from "../components/base/ForceHeader";

export default function StartWorkout({
  navigation,
}: StackScreenProps<WorkoutsParamList, "StartWorkout">) {
  const [workout, setWorkout] = useState<Workout>(
    new Workout({
      name: `Workout at ${moment().format("ll")}`,
      workoutDate: new Date().toISOString(),
    })
  );

  React.useLayoutEffect(() => {
    navigation.setOptions({
      header: () => (
        <ForceHeader>
          <Left>
            <Button transparent onPress={() => navigation.goBack()}>
              <Icon name="arrow-back" />
            </Button>
          </Left>
          <Body>
            <Title>Add New Workout</Title>
          </Body>
          <Right />
        </ForceHeader>
      ),
    });
  }, [navigation]);

  function setInput(
    key: "name" | "description" | "workoutDate",
    value: string
  ) {
    setWorkout(
      Workout.copyOf(workout, (u) => {
        u[key] = value;
      })
    );
  }

  async function addWorkout() {
    try {
      setWorkout(await DataStore.save(workout));
      navigation.pop();
      navigation.navigate('WorkoutDetail', { id: workout.id })
    } catch (err) {
      console.log("error creating workout:", err);
    }
  }

  return (
    <Container>
      <Content padder>
        <Text>Start a new workout by giving it a name</Text>
        <Form>
          <Item>
            <Label>Name</Label>
            <Input
              onChangeText={(val) => setInput("name", val)}
              value={workout.name}
              placeholder="Name"
            />
          </Item>
          <Item>
            <Label>Description</Label>
            <Input
              onChangeText={(val) => setInput("description", val)}
              value={workout.description}
              placeholder="Description"
            />
          </Item>
          <Item>
            <Label>Workout Date</Label>
            <DatePicker
              defaultDate={new Date(workout.workoutDate)}
              locale={"en"}
              onDateChange={(d) => {
                setInput("workoutDate", d.toISOString());
              }}
              formatChosenDate={date => moment(date).format('ll')}
            />
          </Item>
        </Form>
        <Button block onPress={addWorkout}>
          <Icon name="save" />
          <Text>Create Workout</Text>
        </Button>
      </Content>
    </Container>
  );
}
