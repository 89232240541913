/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import { Auth, I18n, Logger } from "aws-amplify";
import { LinearGradient } from 'expo-linear-gradient';
import {
  Body,
  Container,
  Content,

  Form,
  Left,
  Text,
  View
} from "native-base";
import React from "react";
import { Keyboard } from "react-native";
import ForceButton from "../components/base/ForceButton";
import ForceHeader from "../components/base/ForceHeader";
import ForceLogo from "../components/base/ForceLogo";
import FormField from "../components/base/FormField";
import LinkButton from "../components/base/LinkButton";
import { SafeScrollView } from "../components/base/SafeScrollView";
import AuthPiece, { IAuthPieceProps, IAuthPieceState } from "./AuthPiece";

const logger = new Logger("SignIn");

interface ISignInProps extends IAuthPieceProps {}

interface ISignInState extends IAuthPieceState {
  password?: string;
}

export default class SignIn extends AuthPiece<ISignInProps, ISignInState> {
  constructor(props: ISignInProps) {
    super(props);

    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    this.state = {
      username: null,
      password: null,
      error: null,
    };

    this.checkContact = this.checkContact.bind(this);
    this.signIn = this.signIn.bind(this);
  }

  signIn() {
    Keyboard.dismiss();

    const username = this.getUsernameFromInput() || "";
    const { password } = this.state;
    logger.debug("Sign In for " + username);
    return Auth.signIn(username, password)
      .then((user) => {
        logger.debug(user);
        const requireMFA = user.Session !== null;
        if (user.challengeName === "SMS_MFA") {
          this.changeState("confirmSignIn", user);
        } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          logger.debug("require new password", user.challengeParam);
          this.changeState("requireNewPassword", user);
        } else {
          this.checkContact(user);
        }
      })
      .catch((err) => {
        if (err.code === "PasswordResetRequiredException") {
          logger.debug("the user requires a new password");
          this.changeState("forgotPassword", username);
        } else {
          this.error(err);
        }
      });
  }

  showComponent() {
    return (
      <Container>
        <ForceHeader noLeft>
          <Left/>
          <Body>
            <Text>{I18n.get("Sign in")}</Text>
          </Body>
        </ForceHeader>
        <SafeScrollView style={{backgroundColor: '#000'}}>
          <LinearGradient
            // Background Linear Gradient
            colors={["#222", "transparent"]}
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              height: 300,
            }}
          />
          <ForceLogo/>
          <Form>
            {this.renderUsernameField()}
            <FormField
              onChangeText={(text) => this.setState({ password: text })}
              label={I18n.get("Password")}
              placeholder={I18n.get("Enter your password")}
              secureTextEntry={true}
              required={true}
              inputStyle={{color: '#eee'}}
              value={this.state.password}
            />
          </Form>
          <Content padder>
            <ForceButton
              block
              text={I18n.get("Sign In").toUpperCase()}
              onPress={this.signIn}
              disabled={!!(!this.getUsernameFromInput() && this.state.password)}
            />
          </Content>
          <View style={{ justifyContent: "center", flexDirection: "row" }}>
            <LinkButton
              onPress={() => this.changeState("forgotPassword")}
              text={I18n.get("Forgot Password")}
            />
            <LinkButton
              onPress={() => this.changeState("signUp")}
              text={I18n.get("Sign Up")}
            />
          </View>
        </SafeScrollView>
      </Container>
    );
  }
}
