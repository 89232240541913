import { Body, Card, CardItem, Text } from "native-base";
import React from "react";
import Styles from "../../../Styles";

export default function WorkoutExercisePlaceholder() {
  return (
    <Card style={Styles.WorkoutCard}>
      <CardItem header style={Styles.WorkoutCardHeader}>
        <Text style={Styles.WorkoutCardHeaderText}>Exercise</Text>
      </CardItem>
      <CardItem style={Styles.WorkoutCardFooter}>
        <Body>
          <Text>Add an exercise by clicking the + sign.</Text>
        </Body>
      </CardItem>
    </Card>
  );
}
