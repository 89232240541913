import { useIsFocused } from "@react-navigation/native";
import { useEffect } from "react";

export default function useFocused(performFunction: Function) {
    const isFocused = useIsFocused();

    useEffect(() => {
    if (isFocused)
        performFunction();
    }, [isFocused]);
}