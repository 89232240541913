import { Text, View } from "native-base";
import React from "react";
import useTheme from "../../util/useTheme";

export default function ForceLogo() {
  const theme = useTheme();
  return (
    <View style={{ padding: 50, alignItems: "center" }}>
      <View
        style={{
          padding: 10,
          borderColor: "#fff",
          borderWidth: 3,
          justifyContent: "center",
          alignItems: "center",
          width: 200,
          height: 200,
        }}
      >
        <Text
          style={{
            textTransform: "uppercase",
            fontSize: 30,
            letterSpacing: 10,
            fontFamily: theme.Fonts[theme.Fonts.PlayfairDisplay_400Regular],
            color: "#fff"
          }}
        >
          Force
        </Text>
        <Text
          style={{
            textTransform: "uppercase",
            fontSize: 20,
            letterSpacing: 8,
            fontFamily: theme.Fonts[theme.Fonts.PlayfairDisplay_400Regular],
            color: "#fff"
          }}
        >
          Fitness
        </Text>

      </View>
    </View>
  );
}
