/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import React from "react";
import { Auth, I18n, Logger } from "aws-amplify";
import AuthPiece, { IAuthPieceProps, IAuthPieceState } from "./AuthPiece";
import {
  Button,
  Container,
  Content,
  Footer,
  Header,
  Picker,
  Text,
  View,
} from "native-base";
import FormField from "../components/base/FormField";

const logger = new Logger("VerifyContact");

interface IVerifyContactProps extends IAuthPieceProps {}

interface IVerifyContactState extends IAuthPieceState {
  code?: string;
  pickAttr?: string;
  verifyAttr?: string;
}

export default class VerifyContact extends AuthPiece<
  IVerifyContactProps,
  IVerifyContactState
> {
  constructor(props: IVerifyContactProps) {
    super(props);

    this._validAuthStates = ["verifyContact"];
    this.state = {
      verifyAttr: null,
      error: null,
    };

    this.verify = this.verify.bind(this);
    this.submit = this.submit.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.authData) {
      const { unverified } = props.authData;
      if (!unverified) {
        logger.debug("no unverified contact");
        return null;
      }

      const { email, phone_number } = unverified;
      if (email && !state.pickAttr) {
        return {
          pickAttr: "email",
        };
      } else if (phone_number && !state.pickAttr) {
        return {
          pickAttr: "phone_number",
        };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  verify() {
    const user = this.props.authData;
    const attr = this.state.pickAttr;
    if (!attr) {
      this.error("Neither Email nor Phone Number selected");
      return;
    }

    const that = this;
    Auth.verifyCurrentUserAttribute(attr)
      .then((data) => {
        logger.debug(data);
        that.setState({ verifyAttr: attr });
      })
      .catch((err) => this.error(err));
  }

  submit() {
    const attr = this.state.verifyAttr;
    const { code } = this.state;
    Auth.verifyCurrentUserAttributeSubmit(attr, code)
      .then((data) => {
        logger.debug(data);
        this.changeState("signedIn", this.props.authData);
      })
      .catch((err) => this.error(err));
  }

  skip() {
    this.changeState("signedIn");
  }

  // Have to do it in this way to avoid null or undefined element in React.createElement()
  createPicker(unverified: { email?: string; phone_number?: string }) {
    const { email, phone_number } = unverified;
    if (email && phone_number) {
      return (
        <Picker
          selectedValue={this.state.pickAttr}
          onValueChange={(value, index) => this.setState({ pickAttr: value })}
        >
          <Picker.Item label={I18n.get("Email")} value="email" />
          <Picker.Item label={I18n.get("Phone Number")} value="phone_number" />
        </Picker>
      );
    } else if (email) {
      return (
        <Picker
          selectedValue={this.state.pickAttr}
          onValueChange={(value, index) => this.setState({ pickAttr: value })}
        >
          <Picker.Item label={I18n.get("Email")} value="email" />
        </Picker>
      );
    } else if (phone_number) {
      return (
        <Picker
          selectedValue={this.state.pickAttr}
          onValueChange={(value, index) => this.setState({ pickAttr: value })}
        >
          <Picker.Item label={I18n.get("Phone Number")} value="phone_number" />
        </Picker>
      );
    } else {
      return null;
    }
  }

  verifyBody() {
    const { unverified } = this.props.authData;
    if (!unverified) {
      logger.debug("no unverified contact");
      return null;
    }

    const { email, phone_number } = unverified;
    return (
      <View>
        {this.createPicker(unverified)}
        <Button onPress={this.verify} disabled={!this.state.pickAttr}>
          <Text>{I18n.get("Verify")}</Text>
        </Button>
      </View>
    );
  }

  submitBody() {
    return (
      <Content>
        <FormField
          onChangeText={(text) => this.setState({ code: text })}
          label={I18n.get("Confirmation Code")}
          placeholder={I18n.get("Enter your confirmation code")}
          required={true}
          value={this.state.code}
        />
        <Button onPress={this.submit} disabled={!this.state.code}>
          <Text>{I18n.get("Submit")}</Text>
        </Button>
      </Content>
    );
  }

  showComponent() {
    return (
      <Container>
        <Header>{I18n.get("Verify Contact")}</Header>
        <Content>
          {!this.state.verifyAttr && this.verifyBody()}
          {this.state.verifyAttr && this.submitBody()}
        </Content>
        <Footer>
          <Button
            transparent
            onPress={() => this.changeState("signedIn")}
          >
            <Text>{I18n.get("Skip")}</Text>
          </Button>
        </Footer>
      </Container>
    );
  }
}
