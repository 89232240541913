import React from "react";

import useCachedResources from "./hooks/useCachedResources";
import useColorScheme from "./hooks/useColorScheme";
import Navigation from "./src/navigation";

import Amplify, { DataStore, Hub } from "aws-amplify";
import config from "./src/aws-exports";

Amplify.configure({
  ...config,
  Analytics: {
    disabled: true,
  },
});

import withThemedAuthenticator from "./components/WrappedAuthenticator";
import { LogBox } from "react-native";

LogBox.ignoreLogs(['Setting a timer', 'crypto not usable', 'DatePickerIOS has been merged', 'insecure random number generator']);

const App = () => {
  const colorScheme = useColorScheme();
  return (
    <Navigation colorScheme={colorScheme} />
  );
};

export default withThemedAuthenticator(App, {
  usernameAttributes: 'email'
});
