import { Ionicons } from "@expo/vector-icons";
import * as Font from "expo-font";
import * as React from "react";

export default function useCachedResources() {
  const [isLoadingComplete, setLoadingComplete] = React.useState(false);

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        // Load fonts
        await Font.loadAsync({
          ...Ionicons.font,
          "space-mono": require("../assets/fonts/SpaceMono-Regular.ttf"),
          Roboto: require("native-base/Fonts/Roboto.ttf"),
          Roboto_medium: require("native-base/Fonts/Roboto_medium.ttf"),
          HK_Grotesk_Extra_Bold: require("../assets/fonts/HKGrotesk-ExtraBold.ttf") /* font-weight: 800; */,
          HK_Grotesk_Medium: require("../assets/fonts/HKGrotesk-Medium.ttf"),
          HK_Grotesk_Normal: require("../assets/fonts/HKGrotesk-Regular.ttf"),
          HK_Grotesk_Light: require("../assets/fonts/HKGrotesk-Light.ttf"),
          HK_Grotesk_Black: require("../assets/fonts/HKGrotesk-Black.ttf"),
          HK_Grotesk_Bold: require("../assets/fonts/HKGrotesk-Bold.ttf"),
          HK_Grotesk_Semibold: require("../assets/fonts/HKGrotesk-SemiBold.ttf"),
        });
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setLoadingComplete(true);
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  return [isLoadingComplete];
}
