import { StackScreenProps } from "@react-navigation/stack";
import { Body, Button, Container, Content, Left, Text, Title } from "native-base";
import React from "react";
import { AsyncStorage } from "react-native";
import { TabTwoParamList } from "../../types";
import BackButton from "../components/base/BackButton";
import ForceHeader from "../components/base/ForceHeader";
import SystemInfo from "../components/base/SystemInfo";
import useTheme from "../util/useTheme";
import useUserInfo from "../util/useUserInfo";

export default function SystemInfoScreen({
  navigation,
}: StackScreenProps<TabTwoParamList, "SystemInfo">) {
  const [userInfo] = useUserInfo();
  const theme = useTheme();

  React.useLayoutEffect(() => {
    navigation.setOptions({
      header: () => (
        <ForceHeader noLeft>
          <Left>
            <BackButton onPress={() => navigation.goBack()} />
          </Left>
          <Body>
            <Title>Profile</Title>
          </Body>
        </ForceHeader>
      ),
    });
  });

  return (
    <Container style={{ backgroundColor: theme.CoreColors.veryDarkBackground }}>
      <Content padder>
        <SystemInfo />
        <Button onPress={() => {AsyncStorage.clear();}}><Text>Clear AsyncStorage</Text></Button>
      </Content>
    </Container>
  );
}
