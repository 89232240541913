import { Storage } from "aws-amplify";
import * as ImagePicker from "expo-image-picker";
import { Button, Icon, Text, View } from "native-base";
import React, { useEffect, useState } from "react";
import { Image, ImageSourcePropType, Platform } from "react-native";
import uuid from "react-native-uuid";

interface Params {
  filePrefix: string;
  imageUri: string | undefined;
  onImageSelected(imageUri: string): void;
  mode?: "profile" | "imageVideo";
}

export default function ImageVideoPicker({
  filePrefix,
  imageUri,
  mode,
  onImageSelected,
}: Params) {
  const [image, setImage] = useState<ImageSourcePropType>();

  async function fetchData() {
    if (imageUri && imageUri !== "") {
      const uploadedImage = await Storage.get(imageUri, {
        download: false,
      });
      if (typeof uploadedImage === "string") {
        setImage({ uri: uploadedImage });
        console.log('loaded image', uploadedImage);
      } else {
        console.log(uploadedImage);
      }
    }
  }

  useEffect(() => {
    fetchData();
  }, [imageUri]);

  useEffect(() => {
    (async () => {
      if (Platform.OS !== "web") {
        const {
          status,
        } = await ImagePicker.requestCameraRollPermissionsAsync();
        if (status !== "granted") {
          alert("Sorry, we need camera roll permissions to make this work!");
        }
      }
    })();
  }, []);

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: (mode == "profile" ? [1, 1] : [3, 2]),
      quality: 1,
    });

    console.log(result);

    if (!result.cancelled) {
      await uploadToStorage(result.uri);
    }
  };

  const uploadToStorage = async (pathToImageFile: string) => {
    try {
      const response = await fetch(pathToImageFile);
      const blob = await response.blob();
      const key: string = filePrefix + "/" + uuid.v4();

      await Storage.put(key, blob, {
        contentType: blob.type,
      });

      onImageSelected(key);
    } catch (err) {
      console.log(err);
    }
  };

  function ProfileUI() {
    return (
      <View
        padder
        style={{
          alignItems: "center",
        }}
      >
        {image ? (
          <View padder>
            <Image
              source={image}
              style={{ width: 200, height: 200, borderRadius: 100 }}
            />
          </View>
        ) : (
          <View style={{ justifyContent: "center", alignContent: "center" }}>
            <Icon type="Octicons" name="person" style={{ fontSize: 200, width: 200, height: 200, borderRadius: 100 }} />
          </View>
        )}
        <Button
          onPress={pickImage}
          style={{
            borderRadius: 50,
            position: "absolute",
            marginLeft: 170,
            top: 180,
          }}
        >
          <Icon type="AntDesign" name="upload" />
        </Button>
      </View>
    );
  }

  function ImageVideoPickerUI() {
    return (
      <View
        padder
        style={{
          borderRadius: 5,
          borderWidth: 1,
          borderColor: "#ddd",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        {image ? (
          <View padder>
            <Image source={image} style={{ height: 200 }} />
          </View>
        ) : (
          <View style={{ justifyContent: "center", alignContent: "center" }}>
            <Icon type="FontAwesome" name="image" style={{ fontSize: 100 }} />
          </View>
        )}
        <Button block onPress={pickImage}>
          <Icon type="AntDesign" name="upload" />
          <Text>"Upload Image/Video"</Text>
        </Button>
      </View>
    );
  }

  if (mode == "imageVideo") {
    return ImageVideoPickerUI();
  } else {
    return ProfileUI();
  }
}
