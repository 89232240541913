import { useNavigation } from "@react-navigation/native";
import {
  Body,
  Button,
  Card,
  CardItem,
  Icon,
  Left,
  ListItem,
  Right,
  Text,
  View,
} from "native-base";
import React from "react";
import { TouchableOpacity } from "react-native-gesture-handler";
import {
  Menu,
  MenuOption,
  MenuOptions,
  MenuTrigger,
} from "react-native-popup-menu";
import { TrainerFeed } from "../../models";
import useTheme from "../../util/useTheme";
import AmplifyImage from "../base/AmplifyImage";
import moment from "moment";

interface Params {
  feedItem: TrainerFeed;
  isTrainer: boolean;
  onRemoveFeedItem(): void;
}

export default function TrainerFeedCard({
  feedItem,
  isTrainer,
  onRemoveFeedItem,
}: Params) {
  const navigator = useNavigation();
  const theme = useTheme();

  return (
    <Card style={{ borderRadius: 10 }}>
      <CardItem
        cardBody
        style={{
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
          padding: 2,
          paddingTop: 2,
        }}
      >
        <AmplifyImage
          uri={feedItem?.imageVideoUri?.length>0?feedItem.imageVideoUri[0]:''}
          width="100%"
          height={250}
          resizeMode="cover"
          style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
        />
      </CardItem>
      <CardItem header>
        <Body>
          <Text style={{ fontFamily: "Oswald_700Bold" }}>{feedItem.title}</Text>
          <Text
            style={{
              color: theme.CoreColors.lightTextColorSecondary,
              fontSize: 9,
            }}
          >
            Published {moment(feedItem.publishedDate).format("LLLL")}
          </Text>
        </Body>
      </CardItem>
      <CardItem>
        <Text>{feedItem.content}</Text>
      </CardItem>
      {feedItem.type == "WORKOUT" && feedItem.workoutID && feedItem.workoutID.map((workout => (
        <TouchableOpacity
          key={workout}
          onPress={() => {
            navigator.navigate("TrainerWorkoutDetail", {
              id: workout,
            });
          }}
        >
          <CardItem>
            <View
              style={{
                width: "100%",
                borderRadius: 5,
                borderWidth: 1,
                flexDirection: "row",
                alignContent: "space-between",
                padding: 10,
                backgroundColor: "#ccc",
              }}
            >
              <Icon
                style={{ paddingRight: 5 }}
                type="MaterialCommunityIcons"
                name="dumbbell"
              />
              <Text>Workout</Text>
              <Icon
                style={{ top: 10, right: 0, position: "absolute" }}
                type="AntDesign"
                name="right"
              />
            </View>
          </CardItem>
        </TouchableOpacity>
      )))}
      <CardItem
        style={{ borderBottomRightRadius: 10, borderBottomLeftRadius: 10 }}
      >
        <Left>
          <Button transparent>
            <Icon type="Ionicons" name="thumbs-up" />
            <Text>Like</Text>
          </Button>
        </Left>
        {isTrainer && (
          <Right>
            <Menu>
              <MenuTrigger>
                <Icon type="AntDesign" name="ellipsis1" />
              </MenuTrigger>
              <MenuOptions>
                <MenuOption onSelect={onRemoveFeedItem}>
                  <View
                    style={{
                      justifyContent: "flex-start",
                      flexDirection: "row",
                      paddingVertical: 10,
                      paddingHorizontal: 10,
                    }}
                  >
                    <Icon style={{ paddingRight: 10 }} name="trash" />
                    <Text style={{ paddingTop: 4 }}>Delete</Text>
                  </View>
                </MenuOption>
              </MenuOptions>
            </Menu>
          </Right>
        )}
      </CardItem>
    </Card>
  );
}
