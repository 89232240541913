import React, { useState } from "react";
import { StyleSheet } from "react-native";

import {
  Container,
  View,
  Button,
  Body,
  Spinner,
  Header,
  Left,
  Icon,
  Title,
  Right,
  Content,
  H1,
  Text,
} from "native-base";

import { DataStore } from "aws-amplify";

import { StackScreenProps } from "@react-navigation/stack";
import { WorkoutsParamList } from "../../../types";

import {
  TrainerExercise,
  TrainerWorkout,
  TrainerWorkoutSet,
  Workout,
  WorkoutExercise,
  WorkoutSet,
} from "../../models";
import useFocused from "../../util/useFocused";
import TrainerExerciseCard from "../../components/trainer/TrainerExerciseCard";
import ForceHeader from "../../components/base/ForceHeader";

export default function WorkoutDetail({
  navigation,
  route,
}: StackScreenProps<WorkoutsParamList, "WorkoutDetail">) {
  const { id } = route.params;
  const [workout, setWorkout] = useState<TrainerWorkout>(
    new TrainerWorkout({
      name: "",
      description: "",
    })
  );
  const [exercises, setExercises] = useState<TrainerExercise[]>([]);
  const [loading, setLoading] = useState(true);

  useFocused(() => fetchWorkout());

  React.useLayoutEffect(() => {
    navigation.setOptions({
      header: () => (
        <ForceHeader>
          <Left>
            <Button transparent onPress={() => navigation.goBack()}>
              <Icon name="arrow-back" />
            </Button>
          </Left>
          <Body>
            <Title>Workout</Title>
          </Body>
          <Right>
            <Button transparent onPress={() => onCopyWorkout()}>
              <Icon type="FontAwesome5" name="copy" />
            </Button>
          </Right>
        </ForceHeader>
      ),
    });
  });

  async function fetchWorkout() {
    try {
      console.log("fetching workout");
      setLoading(true);
      const w = await DataStore.query(TrainerWorkout, id);
      if (w) {
        await loadExercises(id);
        setWorkout(w);
        setLoading(false);
      }
    } catch (err) {
      console.log("error fetching todos", err);
    }
  }

  async function loadExercises(workoutId: string) {
    const exercises = (await DataStore.query(TrainerExercise)).filter(
      (e) => e.workoutID === workoutId
    );
    setExercises(exercises);
  }

  const styles = StyleSheet.create({
    content: {
      padding: 24,
    },
  });

  async function onCopyWorkout() {
    let newWorkout = new Workout({
      name: workout.name,
      description: workout.description,
      workoutDate: new Date().toISOString(),
    });

    newWorkout = await DataStore.save(newWorkout);

    for (let exercise of exercises) {
      const sets = await DataStore.query(TrainerWorkoutSet, (s) =>
        s.trainerExerciseId("eq", exercise.id)
      );

      const newExercise = await DataStore.save(
        new WorkoutExercise({
          workoutID: newWorkout.id,
          exerciseID: exercise.exerciseID,
        })
      );

      for (let set of sets) {
        const newSet = new WorkoutSet({
          workoutExerciseID: newExercise.id,
          type: set.type,
          name: set.name,
          index: set.index,
          notes: set.notes,
          reps: set.reps,
          weight: set.weight,
        });
        await DataStore.save(newSet);
      }
    }

    navigation.goBack();
  }

  return (
    <Container>
      <Content padder>
        {loading || workout == null ? (
          <Spinner />
        ) : (
          <View>
            <View style={{ paddingBottom: 20 }}>
              <H1>{workout.name}</H1>
              <Text>{workout.description}</Text>
            </View>
            <View>
              {exercises.length > 0 ? (
                exercises.map((exercise, index) => (
                  <TrainerExerciseCard
                    key={exercise.id}
                    workoutExercise={exercise}
                  />
                ))
              ) : (
                <Text />
              )}
            </View>
          </View>
        )}
      </Content>
    </Container>
  );
}
