import { Button, Text } from 'native-base';
import React from 'react';

interface Props {
    text: string
    onPress(): void
    disabled?: boolean
    full?: boolean
    block?: boolean
}

export default function ForceButton(props: Props) {
    return (
        <Button {...props}>
            <Text>{props.text}</Text>
        </Button>
    )
}