import React, { useEffect, useState } from "react";
import {
  Alert,
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
} from "react-native";

import {
  Container,
  View,
  Button,
  Body,
  Spinner,
  Header,
  Left,
  Icon,
  Title,
  Right,
  Content,
  H1,
  Toast,
  Text,
} from "native-base";

import { DataStore } from "aws-amplify";

import { useIsFocused } from "@react-navigation/native";
import { StackScreenProps } from "@react-navigation/stack";
import { WorkoutsParamList } from "../../types";
import WorkoutExerciseCard from "../components/workout/exercise/WorkoutExerciseCard";

import { Exercise, Workout, WorkoutExercise, WorkoutSet } from "../models";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import EditableTextarea from "../components/base/EditableTextarea";
import useFocused from "../util/useFocused";
import { ExerciseItem } from "../util/ExerciseItem";
import WorkoutExercisePlaceholder from "../components/workout/exercise/WorkoutExercisePlaceholder";
import { ScrollView } from "react-native-gesture-handler";
import ForceHeader from "../components/base/ForceHeader";

interface WorkoutContext {
  workout: Workout
  exercises: string[]
}

export default function WorkoutDetail({
  navigation,
  route,
}: StackScreenProps<WorkoutsParamList, "WorkoutDetail">) {
  const { id } = route.params;
  const [{ workout, exercises }, setWorkoutContext] = useState<WorkoutContext>({workout: undefined, exercises: undefined});
  const isFocused = useIsFocused();

  useFocused(() => fetchWorkout());

  React.useLayoutEffect(() => {
    navigation.setOptions({
      header: () => (
        <ForceHeader>
          <Left>
            <Button transparent onPress={onBack}>
              <Icon name="arrow-back" />
            </Button>
          </Left>
          <Body>
            <Title>Workout</Title>
          </Body>
          <Right>
            <Button transparent onPress={() => onDeleteWorkout()}>
              <Icon type="AntDesign" name="delete" style={{ fontSize: 18 }} />
            </Button>
          </Right>
        </ForceHeader>
      ),
    });
  });

  async function onBack() {
    await onSaveWorkout();
    navigation.goBack();
  }

  async function fetchWorkout() {
    try {
      console.info("Fetching workout");
      const w = await DataStore.query(Workout, id);
      if (w) {
        const _exercises = await loadExercises(id);
        setWorkoutContext({ workout: w, exercises: _exercises});
      }
    } catch (err) {
      console.log("error fetching workouts", err);
    }
  }

  async function loadExercises(workoutId: string) : Promise<string[]> {
    const exercises = await DataStore.query(WorkoutExercise, (w) =>
      w.workoutID("eq", workoutId)
    );
    return exercises.map((e) => e.id);
  }

  const styles = StyleSheet.create({
    content: {
      padding: 24,
    },
  });

  async function doDeleteWorkout() {
    await DataStore.delete(workout);
    navigation.goBack();
  }

  async function onDeleteWorkout() {
    // just wait out support for alerts on web
    if (Platform.OS === "web") {
      await doDeleteWorkout();
    } else {
      Alert.alert(
        "Are you sure?",
        "Do you want to delete this workout?",
        [
          {
            text: "Yes",
            onPress: doDeleteWorkout,
          },
          {
            text: "No",
            style: "cancel",
          },
        ],
        { cancelable: false }
      );
    }
  }

  async function onSaveWorkout() {
    try {
      //setLoading(true);
    } catch (e) {
      Toast.show({ text: e });
    }
  }

  async function removeExercise(exerciseId: string) {
    const newExercises = [...exercises.filter((e) => e !== exerciseId)];
    await DataStore.delete(WorkoutExercise, exerciseId);
    setWorkoutContext({workout: workout, exercises: newExercises});
  }

  console.log("re-rendering workout detail");

  return (
    <Container>
      <KeyboardAvoidingView
        style={{ flex: 1, flexDirection: "column", justifyContent: "center" }}
        behavior={Platform.OS == "ios" ? "padding" : "height"}
        enabled
        keyboardVerticalOffset={100}
      >
        <ScrollView keyboardDismissMode="on-drag" style={{padding: 15}}>
          <View style={{ paddingBottom: 10 }}>
            <H1>{workout?.name}</H1>
            <EditableTextarea text={workout?.description} />
          </View>
          <View>
            <View>
              {exercises?.length > 0 ? (
                exercises.map((exercise, index) => (
                  <WorkoutExerciseCard
                    key={exercise}
                    exerciseId={exercise}
                    removeAction={() => removeExercise(exercise)}
                  />
                ))
              ) : (
                <WorkoutExercisePlaceholder />
              )}
              <View style={{ flexDirection: "row" }}>
                <Button
                  dark
                  iconLeft
                  style={{ width: "50%", margin: 5 }}
                  onPress={() =>
                    navigation.navigate("AddExercise", { workoutId: id })
                  }
                >
                  <Icon type="AntDesign" name="plus" />
                  <Text>Exercise</Text>
                </Button>
                <Button
                  iconLeft
                  style={{ flex: 1, margin: 5 }}
                  dark
                  onPress={onBack}
                >
                  <Icon type="AntDesign" name="check" />
                  <Text>Finish</Text>
                </Button>
              </View>
            </View>
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    </Container>
  );
}
