import { StackScreenProps } from "@react-navigation/stack";
import { Auth, API } from "aws-amplify";
import { Body, Button, Container, Icon, Left, List, ListItem, Right, Segment, Text, Header } from "native-base";
import React, { useState } from "react";
import { TabTwoParamList } from "../../types";

interface User {
    id: string,
    name: string
}

export default function AdminScreen({
  navigation,
}: StackScreenProps<TabTwoParamList, "Admin">) {


  let [users, setUsers] = useState(new Array<User>());
  let nextToken: string;

  async function addToGroup(username : string, group : string) {
    let apiName = "AdminQueries";
    let path = "/addUserToGroup";
    let myInit = {
      body: {
        username:  username,
        groupname: group,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    return await API.post(apiName, path, myInit);
  }

  async function listUsers(group: string, limit: number) {
    try {
      let apiName = "AdminQueries";
      let path = group ? "/listUsersInGroup" : "/listUsers";
      let query = {
        limit: limit,
        token: nextToken,
      };
      if (group) {
        query = {
          groupname: group,
          ...query
        }
      }

      let myInit = {
        queryStringParameters: query,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        },
      };
      const { NextToken, ...rest } = await API.get(apiName, path, myInit);
      nextToken = NextToken;

      setUsers(rest.Users);
      console.log(rest.Users);

      return rest;
    } catch (e) {
      console.error(e);
    }
  }

  React.useLayoutEffect(() => {
    navigation.setOptions({
      header: () => (
        <Header>
          <Left>
            <Button transparent onPress={() => navigation.goBack()}>
              <Icon name="arrow-back" />
            </Button>
          </Left>
          <Body>
            <Segment>
              <Button first active onPress={() => listUsers("", 10)}><Text>Users</Text></Button>
              <Button onPress={() => listUsers("experts", 10)}><Text>Experts</Text></Button>
              <Button last onPress={() => listUsers("admin", 10)}><Text>Admins</Text></Button>
            </Segment>
          </Body>
          <Right />
        </Header>
      ),
    });
  }, [navigation, users]);

  return (
    <Container>
      <List>
        {users.map(u => (
        <ListItem key={u.id}>
          <Text>{u.Attributes.find(a => a.Name == "email").Value}</Text>
          <Right><Button onPress={() => addToGroup(u.Username, 'experts')} transparent><Icon name='add'/></Button></Right>
        </ListItem>
        ))}
      </List>
    </Container>
  );
}
