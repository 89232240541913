import { StackScreenProps } from "@react-navigation/stack";
import { DataStore } from "aws-amplify";
import {
  Body,
  Button,
  Container,
  Content,
  Header,
  Icon,
  Left,
  Right,
  Title,
} from "native-base";
import React, { useState } from "react";
import { WorkoutsParamList } from "../../types";
import ExerciseList from "../components/base/ExerciseList";
import { Workout, WorkoutExercise, WorkoutSet } from "../models";

export default function AddExercise({
  navigation,
  route,
}: StackScreenProps<WorkoutsParamList, "AddExercise">) {
  const workoutId = route.params.workoutId;
  const [workoutExercise, setWorkoutExercise] = useState<WorkoutExercise>();

  React.useLayoutEffect(() => {
    navigation.setOptions({
      header: () => (
        <Header>
          <Left>
            <Button transparent onPress={() => navigation.goBack()}>
              <Icon name="ios-arrow-back" />
            </Button>
          </Left>
          <Body>
            <Title>Add Exercise</Title>
          </Body>
          <Right>
            <Button
              transparent
              onPress={addExercise}
              disabled={workoutExercise == undefined || workoutExercise.exerciseID == ""}
            >
              <Icon type="AntDesign" name="check" />
            </Button>
          </Right>
        </Header>
      ),
    });
  }, [navigation, workoutExercise]);

  function setSelectedExercise(id: string) {
    setWorkoutExercise(
      new WorkoutExercise({
        workoutID: workoutId,
        exerciseID: id
      })
    );
  }

  async function addExercise() {
    try {
      await DataStore.save(workoutExercise);
      console.log("going back to workout screen");
      navigation.pop();
    } catch (err) {
      console.log("error creating exercise:", err);
    }
  }

  return (
    <Container>
      <Content>
        <ExerciseList
          onSelect={setSelectedExercise}
          selectedId={workoutExercise?.exerciseID}
        />
      </Content>
    </Container>
  );
}
