import React, { ReactPropTypes, useLayoutEffect, useState } from "react";
import {
  FlexStyle,
  Image,
  ImageResizeMode,
  ImageSourcePropType,
  ImageStyle,
  StyleProp,
} from "react-native";
import { Storage } from "aws-amplify";
import { Content, Spinner, Text, View } from "native-base";
import ForceSpinner from "./ForceSpinner";

interface Params {
  uri?: string;
  width?: string | number;
  height?: string | number;
  resizeMode?: ImageResizeMode;
  style?: StyleProp<ImageStyle>;
}

export default function AmplifyImage({ uri, width, height, style, resizeMode }: Params) {
  const [image, setImage] = useState<ImageSourcePropType>();

  async function fetchData() {
    if (uri && uri !== "") {
      const uploadedImage = await Storage.get(uri, {
        download: false,
      });

      if (typeof uploadedImage === "string") {
        await Image.prefetch(uploadedImage);
        setImage({ uri: uploadedImage });
      }
    }
  }

  useLayoutEffect(() => {
    fetchData();
  }, [uri]);

  return (
    <View
      style={{
        alignContent: "center",
        justifyContent: "center",
        width: width,
        height: height
      }}
    >
      {image ? (
        <Image
          resizeMode={resizeMode}
          style={{ ...style, width: width, height: height }}
          source={image}
        />
      ): <ForceSpinner/>}
    </View>
  );
}
