import React, { useEffect, useState } from "react";

import {
  Container,
  Button,
  Header,
  Body,
  Icon,
  Left,
  Title,
  Right,
  Tab,
  Tabs,
} from "native-base";

import { DataStore, Storage } from "aws-amplify";

import { StackScreenProps } from "@react-navigation/stack";
import { ExerciseManagementParamList } from "../../types";
import { BodyPart, Category, Exercise } from "../models";
import ExerciseDetailTab1 from "../components/exercise/ExerciseDetailTab1";
import ExerciseDetailTabInstructions from "../components/exercise/ExerciseDetailTabInstructions";
import ExerciseDetailTabImage from "../components/exercise/ExerciseDetailTabImage";

export default function ExerciseManagerScreen({
  navigation,
  route,
}: StackScreenProps<ExerciseManagementParamList, "ExerciseDetail">) {
  const { id } = route.params;

  const [exercise, setExercise] = useState<Exercise>(
    new Exercise({
      name: "",
      description: "",
      bodypart: BodyPart.CHEST,
      category: Category.DUMBBELL,
      instructions: [""],
    })
  );

  async function saveExercise() {
    console.log(exercise);
    await DataStore.save(exercise);
    navigation.goBack();
  }

  React.useLayoutEffect(() => {
    navigation.setOptions({
      header: () => (
        <Header hasTabs>
          <Left>
            <Button transparent onPress={() => navigation.goBack()}>
              <Icon name="ios-arrow-back" />
            </Button>
          </Left>
          <Body>
            <Title>Detail</Title>
          </Body>
          <Right>
            <Button transparent onPress={() => saveExercise()}>
              <Icon name="save" />
            </Button>
          </Right>
        </Header>
      ),
    });
  }, [navigation, exercise]);

  useEffect(() => {
    async function fetchData() {
      let exercise;
      if (id !== "") {
        exercise = await DataStore.query(Exercise, id);
        if (exercise !== undefined) {
          setExercise(exercise);
        }
      }
    }

    fetchData();
  }, [id]);

  return (
    <Container>
      <Tabs>
        <Tab heading="Category">
          <ExerciseDetailTab1 exercise={exercise} setExercise={setExercise}/>
        </Tab>
        <Tab heading="Instructions">
          <ExerciseDetailTabInstructions exercise={exercise} setExercise={setExercise}/>
        </Tab>
        <Tab heading="Image/Video">
          <ExerciseDetailTabImage exercise={exercise} setExercise={setExercise}/>
        </Tab>
      </Tabs>
    </Container>
  );
}
