import { View } from 'native-base';
import React from 'react';
import { PropsWithChildren } from "react";
import { KeyboardAvoidingView, StyleProp, ViewStyle } from "react-native";
import { ScrollView } from "react-native-gesture-handler";

interface Props {
    style?: StyleProp<ViewStyle>
}

export function SafeScrollView(props : PropsWithChildren<Props>) {
    return (
        <KeyboardAvoidingView style={{flex: 1}}>
            <ScrollView style={props.style}>
                {props.children}
            </ScrollView>
        </KeyboardAvoidingView>
    )
}