import { StackScreenProps } from "@react-navigation/stack";
import { Auth, DataStore } from "aws-amplify";
import {
  Body,
  Container,
  Content,
  Icon,
  Left,
  Right,
  Text,
  Title,
  View,
} from "native-base";
import React from "react";
import {
  Menu,
  MenuOption,
  MenuOptions,
  MenuTrigger,
} from "react-native-popup-menu";
import { TabTwoParamList } from "../../types";
import EditableText from "../components/base/EditableText";
import ForceHeader from "../components/base/ForceHeader";
import ImageVideoPicker from "../components/base/ImageVideoPicker";
import { Profile } from "../models";
import useDevMode from "../util/useDevMode";
import useTheme from "../util/useTheme";
import useUserInfo from "../util/useUserInfo";

export default function ProfileScreen({
  navigation,
}: StackScreenProps<TabTwoParamList, "Profile">) {
  const [userInfo] = useUserInfo();
  const theme = useTheme();
  const devMode = useDevMode();

  async function signOut() {
    await DataStore.clear();
    await Auth.signOut();
  }

  React.useLayoutEffect(() => {
    navigation.setOptions({
      header: () => (
        <ForceHeader noLeft>
          <Left />
          <Body>
            <Title>Profile</Title>
          </Body>
          <Right>
            <Menu>
              <MenuTrigger>
                <Icon
                  type="AntDesign"
                  name="ellipsis1"
                  style={{ color: theme.toolbarBtnColor }}
                />
              </MenuTrigger>
              <MenuOptions>
                <MenuOption onSelect={signOut}>
                  <View
                    style={{
                      justifyContent: "flex-start",
                      flexDirection: "row",
                      paddingVertical: 10,
                      paddingHorizontal: 10,
                    }}
                  >
                    <Icon
                      style={{ paddingRight: 10 }}
                      color={theme.CoreColors.darkTextColor}
                      name="trash"
                    />
                    <Text style={{ paddingTop: 4 }}>Sign out</Text>
                  </View>
                </MenuOption>
                {userInfo?.isAdmin ? (
                  <MenuOption onSelect={() => navigation.push("Admin")}>
                    <View
                      style={{
                        justifyContent: "flex-start",
                        flexDirection: "row",
                        paddingVertical: 10,
                        paddingHorizontal: 10,
                      }}
                    >
                      <Icon style={{ paddingRight: 10 }} name="person" />
                      <Text>Users</Text>
                    </View>
                  </MenuOption>
                ) : undefined}
                {userInfo?.isAdmin||devMode ? (
                  <MenuOption onSelect={() => navigation.push("SystemInfo")}>
                    <View
                      style={{
                        justifyContent: "flex-start",
                        flexDirection: "row",
                        paddingVertical: 10,
                        paddingHorizontal: 10,
                      }}
                    >
                      <Icon
                        style={{ paddingRight: 10 }}
                        type="AntDesign"
                        name="setting"
                      />
                      <Text>System Info</Text>
                    </View>
                  </MenuOption>
                ) : undefined}
              </MenuOptions>
            </Menu>
          </Right>
        </ForceHeader>
      ),
    });
  });

  function onImageSelected(pathToImageFile: string) {
    userInfo.updateProfile(
      Profile.copyOf(userInfo.profile, (updated) => {
        updated.profilePicture = pathToImageFile;
      })
    );
  }

  function onNameChange(name: string) {
    userInfo.updateProfile(
      Profile.copyOf(userInfo.profile, (updated) => {
        updated.name = name;
      })
    );
  }

  return (
    <Container style={{ backgroundColor: theme.CoreColors.veryDarkBackground }}>
      {userInfo ? (
        <Content padder>
          <View style={{ alignContent: "center", alignItems: "center" }}>
            <ImageVideoPicker
              onImageSelected={onImageSelected}
              filePrefix="profile/"
              mode="profile"
              imageUri={userInfo.profile?.profilePicture}
            ></ImageVideoPicker>
            <EditableText
              large
              onChangeText={(t) => onNameChange(t)}
              style={{ fontSize: 24, color: theme.CoreColors.darkTextColor, fontFamily: theme.TitleFont }}
              textStyle={{ color: theme.CoreColors.darkTextColor }}
              text={userInfo.profile.name}
              placeholder="Name"
            ></EditableText>
            <Text style={{ color: theme.CoreColors.darkTextColor }}>
              {userInfo.email}
            </Text>
            <Text style={{ color: theme.CoreColors.darkTextColor }}>
              {userInfo.groups?.join(", ")}
            </Text>
          </View>
        </Content>
      ) : undefined}
    </Container>
  );
}
