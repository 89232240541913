import { Header, RnViewStyleProp } from "native-base";
import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import useTheme from "../../util/useTheme";

interface HeaderProps {
  hasTabs?: boolean
  noLeft?: boolean
  noShadow?: boolean
  style?: ViewStyle
}

export default function ForceHeader(props: React.PropsWithChildren<HeaderProps>) {
    const theme = useTheme();

  const renderProps = {...props, style: {...props.style}};

  return (<Header
    {...renderProps}
    androidStatusBarColor={theme.statusBarColor}
    iosBarStyle="light-content"
  >
    {props.children}
  </Header>);
}
