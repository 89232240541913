import React from "react";
import { TrainerFeed } from "../../models";
import ImageVideoPicker from "../base/ImageVideoPicker";

interface Params {
  feedItem: TrainerFeed;
  setFeedItem(feedItem: TrainerFeed): any;
}

export default function FeedImage({ feedItem, setFeedItem }: Params) {
  function onImageSelected(pathToImageFile: string) {
    setFeedItem(
      TrainerFeed.copyOf(feedItem, (updated) => {
        updated.imageVideoUri = pathToImageFile;
      })
    );
  }

  return (
    <ImageVideoPicker
      filePrefix="trainerFeed"
      imageUri={feedItem.imageVideoUri}
      onImageSelected={onImageSelected}
    />
  );
}
