import * as React from 'react';

import { createStackNavigator } from "@react-navigation/stack";
import ExerciseManagerScreen from "../screens/ExerciseManager";
import ExerciseDetailScreen from "../screens/ExerciseDetail";
import ExerciseEditorScreen from "../screens/ExerciseEditor";
import { TabTwoParamList } from "../../types";

const ExerciseStack = createStackNavigator<TabTwoParamList>();

export default function ProfileNavigator() {
  return (
    <ExerciseStack.Navigator>
      <ExerciseStack.Screen
        name="ExerciseManager"
        component={ExerciseManagerScreen}
      />
      <ExerciseStack.Screen
        name="ExerciseDetail"
        component={ExerciseDetailScreen}
      />
      <ExerciseStack.Screen
        name="ExerciseEditor"
        component={ExerciseEditorScreen}
      />
    </ExerciseStack.Navigator>
  );
}
