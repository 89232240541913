import { DataStore } from "aws-amplify";
import {
  Body,
  Button,
  Card,
  CardItem,
  Icon,
  Right,
  Text,
  View,
} from "native-base";
import React, { useEffect, useState } from "react";
import { Col } from "react-native-easy-grid";
import {
  Menu,
  MenuOption,
  MenuOptions,
  MenuTrigger,
} from "react-native-popup-menu";
import {
  Exercise,
  WorkoutExercise,
  WorkoutSet,
  WorkoutSetType,
} from "../../../models/index";
import Styles from "../../../Styles";
import useDevMode from "../../../util/useDevMode";
import useTheme from "../../../util/useTheme";
import { SetItem } from "./DataObjects";
import { WorkoutSetGroup } from "./WorkoutSetGroup";
import { WorkoutSetHeaders } from "./WorkoutSetHeaders";
import WorkoutSetRow from "./WorkoutSetRow";

interface WorkoutExerciseParams {
  removeAction?: Function;
  exerciseId: string;
}

export default function WorkoutExerciseCard({
  removeAction,
  exerciseId,
}: WorkoutExerciseParams) {
  const [exercise, setExercise] = useState<Exercise>();
  const [workoutExercise, setWorkoutExercise] = useState<WorkoutExercise>();
  const [sets, setSets] = useState<WorkoutSet[]>([]);
  const [setItems, setSetItems] = useState<SetItem[]>([]);
  const theme = useTheme();
  const devMode = useDevMode();

  async function fetchData() {
    const workoutExercise = await DataStore.query(WorkoutExercise, exerciseId);
    if (workoutExercise) {
      setWorkoutExercise(workoutExercise);
      setSets(
        (await DataStore.query(WorkoutSet, (s) =>
          s.workoutExerciseID("eq", workoutExercise.id)
        )).sort((s1, s2) => s1.index - s2.index)
      );
      setExercise(await DataStore.query(Exercise, workoutExercise.exerciseID));
    }
  }

  useEffect(() => {
    if (exerciseId) fetchData();
  }, [exerciseId]);

  useEffect(() => {
    let setIndex = 0;
    const setItems: SetItem[] | undefined = sets.map((s) => ({
      setId: s.id,
      type: WorkoutSetType[s.type],
      setNumber: s.type == WorkoutSetType.REP ? ++setIndex : setIndex,
      index: s.index
    }));
    setSetItems(setItems || []);
  }, [sets]);

  async function onAddSet() {
    if (workoutExercise) {
      const newSet = await DataStore.save(
        new WorkoutSet({
          workoutExerciseID: workoutExercise.id,
          reps: 0,
          weight: 0,
          type: "REP",
          complete: false,
          index: setItems[setItems.length - 1]
            ? (setItems[setItems.length - 1].index || 0) + 1
            : 1,
        })
      );

      setSets([...sets, newSet]);
    }
  }

  async function onAddGroup() {
    if (workoutExercise) {
      const newSet = await DataStore.save(
        new WorkoutSet({
          workoutExerciseID: workoutExercise.id,
          name: "Group",
          type: WorkoutSetType.GROUP,
          index: setItems[setItems.length - 1]
            ? (setItems[setItems.length - 1].index || 0) + 1
            : 1,
        })
      );

      setSets([...sets, newSet]);
    }
  }

  async function onRemoveExercise() {
    if (removeAction) removeAction();
  }

  return (
    <Card style={Styles.WorkoutCard}>
      <CardItem
        header
        style={Styles.WorkoutCardHeader}
      >
        <Body>
          <Text
            style={Styles.WorkoutCardHeaderText}
          >
            {exercise?.name} 
          </Text>
          {devMode && <Text>{`Workout ID: ${workoutExercise?.id}`}</Text>}
        </Body>
        <Right>
          <Menu>
            <MenuTrigger>
              <Icon type="AntDesign" name="ellipsis1" />
            </MenuTrigger>
            <MenuOptions>
              <MenuOption onSelect={onRemoveExercise}>
                <View
                  style={{
                    justifyContent: "flex-start",
                    flexDirection: "row",
                    paddingVertical: 10,
                    paddingHorizontal: 10,
                  }}
                >
                  <Icon style={{ paddingRight: 10 }} name="trash" />
                  <Text style={{ paddingTop: 4 }}>Delete</Text>
                </View>
              </MenuOption>
            </MenuOptions>
          </Menu>
        </Right>
      </CardItem>
      {setItems.map((setItem, index) =>
        setItem.type == "REP" ? (
          <WorkoutSetRow
            key={setItem.setId}
            setId={setItem.setId}
            setIndex={setItem.setNumber}
          />
        ) : (
          <View key={setItem.setId} style={{ alignSelf: "stretch" }}>
            <WorkoutSetGroup
              setId={setItem.setId}
            />
            <WorkoutSetHeaders />
          </View>
        )
      )}
      <CardItem
        footer
        style={Styles.WorkoutCardFooter}
      >
        <Col>
          <Button transparent onPress={onAddSet}>
            <Icon type="AntDesign" name="plus" />
            <Text>Set</Text>
          </Button>
        </Col>
        <Col>
          <Button transparent onPress={onAddGroup}>
            <Icon type="FontAwesome5" name="layer-group" />
            <Text>Group</Text>
          </Button>
        </Col>
      </CardItem>
    </Card>
  );
}
