import { Content, H1, Text, View } from "native-base";
import React from "react";
import { Exercise } from "../../models";
import ImageVideoPicker from "../base/ImageVideoPicker";

interface Params {
  exercise: Exercise;
  setExercise(exercise: Exercise): any;
}

export default function ({ exercise, setExercise }: Params) {
  function onImageSelected(pathToImageFile: string) {
    setExercise(
      Exercise.copyOf(exercise, (updated) => {
        updated.video = pathToImageFile;
      })
    );
  }

  return (
    <Content padder>
      <H1>Video</H1>
      <ImageVideoPicker
        filePrefix="exercises/"
        onImageSelected={onImageSelected}
        imageUri={exercise.video}
      />
      <H1>Images</H1>
      {exercise.images.map((i) => (
        <View>
          <Text>{i}</Text>
        <ImageVideoPicker
          key={i}
          filePrefix="exercises/"
          onImageSelected={() => {
            alert("not supported");
          }}
          imageUri={i}
        />
        </View>
      ))}
    </Content>
  );
}
