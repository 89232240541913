import { I18n } from "aws-amplify";
import { Input, Item, Label } from "native-base";
import React from "react";
import { StyleProp, TextInputProps, TextStyle } from "react-native";
import { State } from "react-native-gesture-handler";

interface FormFieldParams {
  onChangeText(text: string): void;
  placeholder: string;
  required: boolean;
  value: any;
  label: string;
  secureTextEntry?: boolean;
  type?: "email"|"password"
  inputStyle?: StyleProp<TextStyle>
}

export default function (params: FormFieldParams) {
  return (
    <Item stackedLabel>
      <Label>{params.label}</Label>
      <Input
        onChangeText={(text) => params.onChangeText(text)}
        value={params.value}
        secureTextEntry={params.secureTextEntry}
        autoCapitalize={params.type=="email"?"none":undefined}
        keyboardType={params.type=="email"?"email-address":undefined}
        style={params.inputStyle}
        
      />
    </Item>
  );
}
