import { DataStore } from "aws-amplify";
import { CardItem, CheckBox, Input, Text } from "native-base";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-native-easy-grid";
import { WorkoutSet } from "../../../models/index";
import { SetItem } from "./DataObjects";
import  _Styles from '../../../Styles';

const Styles = {
  Input: {
      width: '90%',
      borderWidth: 1,
      borderRadius: 5,
      borderColor: '#ccc',
      height: 35,
      fontSize: 12,
  },
  ..._Styles
};

interface WorkoutSetParams {
  setId: string;
  setIndex: number;
}

export default function WorkoutSetRow({setId, setIndex}: WorkoutSetParams) {
  const [set, setSet] = useState<WorkoutSet>();
  const [dirty, setDirty] = useState(false);

  const valueRef = useRef<{set?:WorkoutSet,dirty:boolean}>({dirty: false});

  async function fetchData() {
    const set = await DataStore.query(WorkoutSet, setId);
    if (set) {
      setSet(set);
    }
  }

  useEffect(() => {
    if (setId) fetchData();
    return async function cleanup() {
      if (valueRef?.current && valueRef.current.set && valueRef.current.dirty) {
        console.info(`Saving ${valueRef.current.set.id}`);
        await DataStore.save(valueRef.current.set);
      }
    }
  }, [setId]);

  useEffect(() => {
    valueRef.current = {set: set, dirty: dirty};
  }, [set, dirty]);

  function setNumericInput(
    field: "reps" | "weight",
    value: string
  ) {
    try {
      const numericValue = parseInt(value) || 0;
      const newSet = WorkoutSet.copyOf(set, (u) => {
        u[field] = numericValue;
      });
      setSet(newSet);
      setDirty(true);
    } catch (e) {
      console.error(e, set);
    }
  }

  async function setToggle() {
    const newSet = WorkoutSet.copyOf(set, (u) => {
      u.complete = !u.complete;
    });
    setSet(newSet);
    setDirty(true);
  }

  function formatInput(set: WorkoutSet, field: "reps" | "weight"): string {
    return set && set[field] && set[field] != 0 ? set[field].toString() : "";
  }

  return (
    <CardItem style={Styles.WorkoutSetRow}>
      <Col size={1} style={{ justifyContent: "center", margin: 0, padding: 0 }}>
        <Text style={{fontSize: 12}}>{setIndex}</Text>
      </Col>
      <Col size={3} style={{ justifyContent: "center", margin: 0, padding: 0 }}>
        {set? <Input
          keyboardType={"number-pad"}
          style={Styles.Input}
          value={formatInput(set, "reps")}
          onChangeText={(val) => setNumericInput("reps", val)}
        />: undefined}
      </Col>
      <Col size={3} style={{ justifyContent: "center", margin: 0, padding: 0 }}>
        {set? <Input
          keyboardType={"decimal-pad"}
          style={Styles.Input}
          value={formatInput(set, "weight")}
          onChangeText={(val) => setNumericInput("weight", val)}
        />: undefined}
      </Col>
      <Col size={1} style={{ justifyContent: "center", margin: 0, padding: 0 }}>
        <CheckBox checked={set?.complete} onPress={setToggle} />
      </Col>
    </CardItem>
  );
}
