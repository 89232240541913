import { StackScreenProps } from '@react-navigation/stack';
import { Content, Button, Text} from 'native-base';
import * as React from 'react';

import { RootStackParamList } from '../../types';

export default function NotFoundScreen({
  navigation,
}: StackScreenProps<RootStackParamList, 'NotFound'>) {
  return (
    <Content>
      <Text>This screen doesn't exist.</Text>
      <Button onPress={() => navigation.replace('Root')}>
        <Text>Go to home screen!</Text>
      </Button>
    </Content>
  );
}