import * as React from 'react';

import { createStackNavigator } from "@react-navigation/stack";
import { TrainerParamList } from "../../types";
import TrainerFeedScreen from '../screens/trainer/TrainerFeed';
import TrainerPublishScreen from '../screens/trainer/TrainerPublish';
import TrainerWorkoutDetail from '../screens/trainer/TrainerWorkoutDetail';

const Profile = createStackNavigator<TrainerParamList>();

export default function ProfileNavigator() {
  return (
    <Profile.Navigator>
      <Profile.Screen
        name="Trainer"
        component={TrainerFeedScreen}
      />
      <Profile.Screen
        name="TrainerPublishContent"
        component={TrainerPublishScreen}
      />
      <Profile.Screen
        name="TrainerWorkoutDetail"
        component={TrainerWorkoutDetail}
      />
    </Profile.Navigator>
  );
}
