import { StackScreenProps } from "@react-navigation/stack";
import { Auth, DataStore } from "aws-amplify";
import {
  Body,
  Button,
  Container,
  Content,
  Header,
  Icon,
  Input,
  Label,
  Left,
  ListItem,
  Picker,
  Right,
  Segment,
  Switch,
  Text,
  Textarea,
  Title,
} from "native-base";
import React, { useState } from "react";
import { TrainerParamList } from "../../../types";
import FeedImage from "../../components/trainer/FeedImage";
import {
  TrainerExercise,
  TrainerFeed,
  TrainerWorkout,
  TrainerWorkoutSet,
  Workout,
  WorkoutExercise,
  WorkoutSet,
  WorkoutSetType,
} from "../../models";
import useFocused from "../../util/useFocused";

export default function TrainerPublishScreen({
  navigation,
}: StackScreenProps<TrainerParamList, "Trainer">) {
  const [feedItem, setFeedItem] = useState(
    new TrainerFeed({
      title: "",
      author: "",
      published: false,
      publishedDate: new Date().toISOString(),
      type: "WORKOUT",
    })
  );
  const [workouts, setWorkouts] = useState(new Array<Workout>());
  const [loading, setLoading] = useState(true);
  const [workoutId, setWorkoutId] = useState("");

  useFocused(() => fetchData());

  React.useLayoutEffect(() => {
    navigation.setOptions({
      header: () => (
        <Header hasTabs>
          <Left>
            <Button transparent onPress={() => navigation.goBack()}>
              <Icon name="arrow-back" />
            </Button>
          </Left>
          <Body>
            <Title>Publish</Title>
          </Body>
          <Right>
            <Button transparent onPress={onPublish}>
              <Icon type="MaterialIcons" name="publish" />
            </Button>
          </Right>
        </Header>
      ),
    });
  }, [navigation, feedItem]);

  async function onPublish() {
    let newFeedItem = feedItem;

    if (feedItem.published && feedItem.publishedDate == "") {
      newFeedItem = TrainerFeed.copyOf(feedItem, (u) => {
        u.publishedDate = new Date().toISOString();
      });
    }
    if (workoutId != "") {
      // we need to build and clone a workout from
      // the trainers personal workouts
      const workout = workouts.find((w) => w.id == workoutId);

      if (workout) {
        const trainerWorkout = await DataStore.save(
          new TrainerWorkout({
            description: workout.description || feedItem.content || "",
            name: workout.name,
            owner: feedItem.author,
          })
        );

        const exercises = await DataStore.query(WorkoutExercise, (w) =>
          w.workoutID("eq", workoutId)
        );

        if (exercises) {
          for (let exercise of exercises) {
            const sets = await DataStore.query(WorkoutSet, (s) =>
              s.workoutExerciseID("eq", exercise.id)
            );

            const trainerExercise = await DataStore.save(
              new TrainerExercise({
                workoutID: trainerWorkout.id,
                exerciseID: exercise.exerciseID,
                owner: feedItem.author,
              })
            );

            for (let workoutSet of sets) {
              await DataStore.save(new TrainerWorkoutSet({
                type:     WorkoutSetType[workoutSet.type],
                name:     workoutSet.name,
                notes:    workoutSet.notes,
                trainerExerciseId: trainerExercise.id,
                reps:     workoutSet.reps,
                weight:   workoutSet.weight,
                index:    workoutSet.index,
              }));
            }
          }
        }

        newFeedItem = TrainerFeed.copyOf(feedItem, (u) => {
          u.workoutID = trainerWorkout.id;
        });
      }
    }

    newFeedItem = await DataStore.save(newFeedItem);
    setFeedItem(newFeedItem);

    navigation.goBack();
  }

  async function fetchData() {
    setLoading(true);
    await fetchWorkouts();
    const user = await Auth.currentAuthenticatedUser();
    setAuthor(user.username);
    setLoading(false);
  }

  async function fetchWorkouts() {
    try {
      const workouts = await DataStore.query(Workout);
      setWorkouts(workouts);
    } catch (err) {
      console.log("error fetching workouts", err);
    }
  }

  function setAuthor(userId: string) {
    setFeedItem(
      TrainerFeed.copyOf(feedItem, (u) => {
        u.author = userId;
      })
    );
  }

  function onSetFeedValue(field: "title" | "content", value: string) {
    setFeedItem(
      TrainerFeed.copyOf(feedItem, (u) => {
        u[field] = value;
      })
    );
  }

  function togglePublished(newValue: boolean) {
    setFeedItem(
      TrainerFeed.copyOf(feedItem, (u) => {
        u.published = newValue;
      })
    );
  }

  function setWorkout(workoutId: string) {
    setWorkoutId(workoutId);
  }

  return (
    <Container>
      <Segment>
        <Button first active>
          <Text>Workout</Text>
        </Button>
        <Button last>
          <Text>Lifestyle</Text>
        </Button>
      </Segment>
      <Content>
        <ListItem>
          <Body>
            <Input
              placeholder="Post Title"
              onChangeText={(text) => {
                onSetFeedValue("title", text);
              }}
            />
          </Body>
        </ListItem>
        <ListItem>
          <Body>
            <Label>Published</Label>
          </Body>
          <Right>
            <Switch
              value={feedItem.published}
              onValueChange={togglePublished}
            />
          </Right>
        </ListItem>
        <ListItem>
          <Picker
            mode="dropdown"
            placeholder="Pick the workout to publish"
            iosIcon={<Icon name="arrow-down" />}
            selectedValue={workoutId}
            onValueChange={setWorkout}
          >
            <Picker.Item label="" value="" />
            {workouts.map((workout) => (
              <Picker.Item
                key={workout.id}
                label={workout.name}
                value={workout.id}
              />
            ))}
          </Picker>
        </ListItem>
        <ListItem>
          <Textarea
            bordered={false}
            rowSpan={5}
            placeholder="What's on your mind"
            onChangeText={(text) => {
              onSetFeedValue("content", text);
            }}
          />
        </ListItem>
        <ListItem>
          <FeedImage feedItem={feedItem} setFeedItem={setFeedItem} />
        </ListItem>
      </Content>
    </Container>
  );
}
