import React, {
  ComponentType,
  ComponentPropsWithRef,
  FunctionComponent,
  useEffect,
} from "react";
import { Hub, Logger } from "@aws-amplify/core";
import { Root, StyleProvider } from "native-base";
import { Authenticator, Greetings } from "../src/auth";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { MenuProvider } from "react-native-popup-menu";
import getTheme from "../native-base-theme/components";
import commonColor from "../native-base-theme/variables/commonColor";
import * as SplashScreen from "expo-splash-screen";

import useCachedResources from "../hooks/useCachedResources";
import { AppLoading } from "expo";
import {
  useFonts,
  Oswald_300Light,
  Oswald_400Regular,
  Oswald_500Medium,
  Oswald_700Bold,
} from "@expo-google-fonts/oswald";
import { PlayfairDisplay_400Regular } from "@expo-google-fonts/playfair-display";
import { DataStore } from "aws-amplify";
import { Configuration, Profile, Workout } from "../src/models";
import { LogBox, StatusBar } from "react-native";

import { HubCapsule } from "aws-amplify-react-native";
import { isLoading } from "expo-font";
import Welcome from "../src/screens/Welcome";

const logger = new Logger("WrappedAuthenticator");

enum Mode {
  Loading,
  NotLoggedIn,
  LoggedIn,
  Loaded,
  Welcome,
  HasProfile,
}

export default function withThemedAuthenticator(
  Component: ComponentType,
  authenticatorProps?: ComponentPropsWithRef<typeof Authenticator>
) {
  const AppWithAuthenticator: FunctionComponent = (props) => {
    const [mode, setMode] = React.useState(Mode.Loading);

    const [isLoadingComplete] = useCachedResources();
    const [fontsLoaded, fontsErr] = useFonts({
      Oswald_300Light,
      Oswald_400Regular,
      Oswald_500Medium,
      Oswald_700Bold,
      PlayfairDisplay_400Regular,
    });

    async function checkUser(authState) {
      try {
        console.log(`Changing auth state: ${authState}`);
        if (authState === "signedIn" && mode == Mode.NotLoggedIn) {
          setMode(Mode.LoggedIn);
        } else if (authState === "signIn") {
          try {
            await DataStore.clear();
          } catch (e) {
            console.log("Failed to clear datastore", e);
          }
          setMode(Mode.NotLoggedIn);
          SplashScreen.hideAsync();
        }
      } catch (e) {
        console.error(e);
      }
    }

    async function loadData() {
      try {
        let userProfile = await DataStore.query(Profile);
        if (userProfile.length == 0 || !userProfile[0].name) {
          setMode(Mode.Welcome);
        } else {
          setMode(Mode.HasProfile);
        }
        SplashScreen.hideAsync();
      } catch (e) {
        console.error(e);
      }
    }

    function completeProfile() {
      setMode(Mode.HasProfile);
    }

    useEffect(() => {
      console.info("Mode = " + Mode[mode]);
      if (mode == Mode.LoggedIn) {
        // load config just to make sure datastore is ready
        loadData();
      }
    }, [mode]);

    useEffect(() => {
      if (mode == Mode.Loading && isLoadingComplete && fontsLoaded) {
        setMode(Mode.NotLoggedIn);
      }
    }, [isLoading, fontsLoaded]);

    useEffect(() => {
      const listener = (capsule: HubCapsule) => {
        console.log(capsule);
      };
      Hub.listen("DataStore", listener);

      return function cleanup() {
        Hub.remove("DataStore", listener);
      };
    }, []);

    if (mode == Mode.Loading) {
      return <AppLoading autoHideSplash={false} />;
    } else {
      return (
        <SafeAreaProvider>
          <StyleProvider style={getTheme(commonColor)}>
            <Root>
              <MenuProvider>
                <Authenticator
                  {...authenticatorProps}
                  {...props}
                  onStateChange={checkUser}
                >
                  {mode == Mode.HasProfile ? (
                    <Component />
                  ) : mode == Mode.Welcome ? (
                    <Welcome onComplete={completeProfile} />
                  ) : undefined}
                </Authenticator>
              </MenuProvider>
            </Root>
          </StyleProvider>
        </SafeAreaProvider>
      );
    }
  };

  return AppWithAuthenticator;
}
