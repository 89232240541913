import useTheme from "./util/useTheme";

const theme = useTheme();

export default {
  InputBorder: {
    borderWidth: 1,
    borderRadius: 5,
    borderColor: "#ccc",
  },
  WorkoutCard: {
    borderRadius: 10,
    backgroundColor: "#eee",
  },
  WorkoutCardHeader: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    backgroundColor: "#222",
  },
  WorkoutCardHeaderText: {
    fontFamily: theme.Fonts[theme.Fonts.Oswald_700Bold],
    color: "#eee",
  },
  WorkoutCardFooter: {
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    backgroundColor: "#eee",
  },
  WorkoutSetRow: {
    backgroundColor: '#eee', margin: 0, padding: 0
  },
  WorkoutSetHeaderRow: {
    paddingLeft: 16,
    paddingTop: 20,
    borderBottomWidth: 1,
    borderBottomColor: "#aaa",
  },
  WorkoutSetHeaderText: {
    fontFamily: theme.Fonts[theme.Fonts.Oswald_500Medium],
    fontSize: 12,
  },
  WorkoutSetHeaderIcon: {
    fontSize: 12
  }
};
