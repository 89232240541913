import { Button, Icon } from 'native-base';
import React from 'react';

interface Props {
    transparent?: boolean
    onPress?(): void
}

export default function BackButton(props: Props) {
    return (<Button onPress={props.onPress} transparent={props.transparent||true}><Icon name="arrow-back"/></Button>)
}