/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import React from "react";
import { Auth, I18n, Logger, JS } from "aws-amplify";
import AuthPiece, { IAuthPieceProps, IAuthPieceState } from "./AuthPiece";
import { Body, Button, Container, Content, Footer, Text } from "native-base";
import { Header } from "react-native/Libraries/NewAppScreen";
import FormField from "../components/base/FormField";

const logger = new Logger("ConfirmSignIn");

interface IConfirmSignInProps extends IAuthPieceProps {}

interface IConfirmSignInState extends IAuthPieceState {
  code?: string;
}

export default class ConfirmSignIn extends AuthPiece<
  IConfirmSignInProps,
  IConfirmSignInState
> {
  constructor(props: IConfirmSignInProps) {
    super(props);

    this._validAuthStates = ["confirmSignIn"];
    this.state = {
      code: null,
      error: null,
    };

    this.confirm = this.confirm.bind(this);
    this.checkContact = this.checkContact.bind(this);
  }

  confirm() {
    const user = this.props.authData;
    const { code } = this.state;
    logger.debug("Confirm Sign In for " + user.username);
    Auth.confirmSignIn(user, code)
      .then((data) => this.checkContact(user))
      .catch((err) => this.error(err));
  }

  showComponent() {
    return (
      <Container>
        <Header>
          <Body>
            <Text>{I18n.get("Confirm Sign In")}</Text>
          </Body>
        </Header>
        <Content>
          <Content>
            <FormField
              onChangeText={(text) => this.setState({ code: text })}
              label={I18n.get("Confirmation Code")}
              placeholder={I18n.get("Enter your confirmation code")}
              required={true}
              value={this.state.code}
            />
            <Button onPress={this.confirm} disabled={!this.state.code}>
              <Text>{I18n.get("Confirm")}</Text>
            </Button>
          </Content>
          <Footer>
            <Button transparent onPress={() => this.changeState("signIn")}>
              {I18n.get("Back to Sign In")}
            </Button>
          </Footer>
        </Content>
      </Container>
    );
  }
}
