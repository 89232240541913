import { DataStore } from "aws-amplify";
import {
  Body,
  Card,
  CardItem,
  CheckBox,
  Col,
  Grid,
  Icon,
  Input,
  Row,
  Text,
} from "native-base";
import React, { useEffect, useState } from "react";
import { Exercise, TrainerExercise, TrainerWorkoutSet } from "../../models";
import Styles from "../../Styles";
import useTheme from "../../util/useTheme";

interface TrainerSetItem {
  displayIndex?: number;
  set: TrainerWorkoutSet;
}

export default function TrainerExerciseCard({
  workoutExercise,
}: {
  workoutExercise: TrainerExercise;
}) {
  const [exercise, setExercise] = useState<Exercise>();
  const [sets, setSets] = useState<TrainerSetItem[]>();
  const theme = useTheme();

  const fetchData = async () => {
    const exercise = await DataStore.query(
      Exercise,
      workoutExercise?.exerciseID
    );
    if (exercise) setExercise(exercise);

    const sets = await DataStore.query(TrainerWorkoutSet, (s) =>
      s.trainerExerciseId("eq", workoutExercise.id)
    );

    let displayIndex = 0;
    const setItems = sets
      .sort((s1, s2) => s1.index - s2.index)
      .map((s) => ({
        displayIndex: s.type == "REP" ? ++displayIndex : undefined,
        set: s,
      }));

    setSets(setItems);
  };

  useEffect(() => {
    if (workoutExercise.exerciseID) fetchData();
  }, [workoutExercise]);

  return (
    <Card style={Styles.WorkoutCard}>
      <CardItem header style={Styles.WorkoutCardHeader}>
        <Text style={Styles.WorkoutCardHeaderText}>
          {exercise && exercise.name}
        </Text>
      </CardItem>
      <CardItem
        style={{
          ...Styles.WorkoutSetHeaderRow,
          backgroundColor: Styles.WorkoutCard.backgroundColor,
        }}
      >
        <Row>
          <Col size={1}>
            <Text
              style={{ ...Styles.WorkoutSetHeaderText, textAlign: "center" }}
            >
              Set
            </Text>
          </Col>
          <Col size={3}>
            <Text
              style={{ ...Styles.WorkoutSetHeaderText, textAlign: "center" }}
            >
              Reps
            </Text>
          </Col>
          <Col size={3}>
            <Text
              style={{ ...Styles.WorkoutSetHeaderText, textAlign: "center" }}
            >
              Weight
            </Text>
          </Col>
        </Row>
      </CardItem>
      {sets?.map((set, index) =>
        set.set.type == "GROUP" ? (
          <CardItem
            key={set.set.id}
            style={{
              borderRadius: 0,
              padding: 0,
              margin: 0,
              backgroundColor: Styles.WorkoutCard.backgroundColor,
            }}
          >
            <Text style={{ fontFamily: theme.BoldFont }}>{set.set.name}</Text>
          </CardItem>
        ) : (
          <CardItem key={set.set.id} style={Styles.WorkoutSetRow}>
            <Row>
              <Col size={1}>
                <Text
                  style={{
                    backgroundColor: "#222",
                    color: "#eee",
                    borderRadius: 10,
                    textAlign: "center",
                  }}
                >
                  {set.displayIndex}
                </Text>
              </Col>
              <Col size={3}>
                <Text style={{ textAlign: "center" }}>
                  {set ? set.set.reps : 0}
                </Text>
              </Col>
              <Col size={3}>
                <Text style={{ textAlign: "center" }}>
                  {set ? set.set.weight : 0}
                </Text>
              </Col>
            </Row>
          </CardItem>
        )
      )}
    </Card>
  );
}
