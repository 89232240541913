import React from 'react';
import Constants from 'expo-constants';
import { Text, View } from 'native-base';

export default function SystemInfo() {
    let releaseChannel = Constants.manifest.releaseChannel;

    return (
        <View style={{borderRadius: 10, borderColor: '#222', backgroundColor: '#333', padding: 20}}>
            <Text style={{color: '#aaa'}}>
                Release Channel: {releaseChannel}
            </Text>
        </View>
    );
}