// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const WorkoutSetType = {
  "GROUP": "GROUP",
  "REP": "REP",
  "WARMUP": "WARMUP"
};

const SubscriptionType = {
  "TRIAL": "TRIAL",
  "FREE": "FREE",
  "PAID": "PAID"
};

const SubscriptionStatus = {
  "PENDING": "PENDING",
  "ACTIVE": "ACTIVE",
  "EXPIRED": "EXPIRED"
};

const TrainerFeedType = {
  "WORKOUT": "WORKOUT",
  "MEAL_PLAN": "MEAL_PLAN",
  "PERSONAL_PLAN": "PERSONAL_PLAN",
  "LIFESTYLE": "LIFESTYLE"
};

const BodyPart = {
  "CORE": "CORE",
  "ARMS": "ARMS",
  "BACK": "BACK",
  "CHEST": "CHEST",
  "LEGS": "LEGS",
  "SHOULDERS": "SHOULDERS",
  "OTHER": "OTHER",
  "FULL_BODY": "FULL_BODY"
};

const Category = {
  "BARBELL": "BARBELL",
  "DUMBBELL": "DUMBBELL",
  "MACHINE": "MACHINE",
  "WEIGHTED_BODYWEIGHT": "WEIGHTED_BODYWEIGHT",
  "ASSISTED_BODYWEIGHT": "ASSISTED_BODYWEIGHT",
  "REPS": "REPS",
  "CARDIO": "CARDIO",
  "DURATION": "DURATION"
};

const { Profile, Workout, WorkoutExercise, WorkoutSet, UserTrainerSubscription, TrainerFeed, TrainerWorkout, TrainerExercise, TrainerWorkoutSet, Exercise, Configuration } = initSchema(schema);

export {
  Profile,
  Workout,
  WorkoutExercise,
  WorkoutSet,
  UserTrainerSubscription,
  TrainerFeed,
  TrainerWorkout,
  TrainerExercise,
  TrainerWorkoutSet,
  Exercise,
  Configuration,
  WorkoutSetType,
  SubscriptionType,
  SubscriptionStatus,
  TrainerFeedType,
  BodyPart,
  Category
};