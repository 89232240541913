/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import React from "react";
import { Auth, I18n } from "aws-amplify";
import AuthPiece, { IAuthPieceProps, IAuthPieceState } from "./AuthPiece";
import { Button, Content, Text, View } from "native-base";
import { Image } from "react-native";
import splashImage from "../../assets/images/splash.png";

interface IGreetingsProps extends IAuthPieceProps {
  signedInMessage?: string;
  signedOutMessage?: string;
}

interface IGreetingsState extends IAuthPieceState {}

export default class Greetings extends AuthPiece<
  IGreetingsProps,
  IGreetingsState
> {
  constructor(props: IGreetingsProps) {
    super(props);
    this._validAuthStates = ["signedIn"];
    this.signOut = this.signOut.bind(this);
    this.getMessage = this.getMessage.bind(this);
  }

  signOut() {
    Auth.signOut()
      .then(() => this.changeState("signedOut"))
      .catch((err) => this.error(err));
  }

  getMessage() {
    const { authData } = this.props;
    let defaultMessage = "";
    const user = authData;
    if (user) {
      const { usernameAttributes = [] } = this.props;
      let name = "";
      if (usernameAttributes === "email") {
        // Email as Username
        name = user.attributes ? user.attributes.email : user.username;
        defaultMessage = `${name}`;
      } else if (usernameAttributes === "phone_number") {
        // Phone number as Username
        name = user.attributes ? user.attributes.phone_number : user.username;
        defaultMessage = `${name}`;
      } else {
        name = user.username || "unknown user";
        defaultMessage = `${I18n.get("Hello")} ${name}`;
      }
    }
    return this.props.signedInMessage || defaultMessage;
  }

  showComponent() {
    return (
      <Image
        source={splashImage}
        resizeMode="contain"
        resizeMethod="scale"
        style={{ flex: 1 }}
      />
    );
  }
}
