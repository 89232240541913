import * as Linking from 'expo-linking';

export default {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Root: {
        screens: {
          WorkoutsStack: {
            path: 'workout',
            initialRouteName: 'Workouts',
            screens: {
              Workouts: 'main',
              WorkoutDetail: 'workoutDetails/:id'
            },
          },
          TrainerStack: {
            path: 'trainer',
            initialRouteName: 'Trainer',
            screens: {
              Trainer: 'main',
              TrainerPublishContent: 'publish'
            }
          },
          ExerciseStack: {
            path: 'exercises',
            initialRouteName: "ExerciseManager",
            screens: {
              ExerciseManager: 'main',
              ExerciseDetail: 'detail'
            }
          },
          ProfileStack: {
            path: 'profile',
            initialRouteName: 'Profile',
            screens: {
              Profile: 'main',
              Admin: 'admin'
            },
          }
        },
      },
      NotFound: '*',
    },
  },
};
