import React, { useEffect, useState } from "react";
import { RefreshControl, SectionList, StyleSheet } from "react-native";

import {
  Text,
  ListItem,
  List,
  Separator,
  View,
  Input,
  Icon,
  Left,
  Right,
} from "native-base";
import { DataStore } from "aws-amplify";
import { BodyPart, Exercise } from "../../models";
import { useIsFocused } from "@react-navigation/native";
import { FlatList } from "react-native-gesture-handler";

interface ExerciseListParams {
  onSelect(exerciseId: string): void;
  selectedId?: string;
}

export default function ExerciseList({
  onSelect,
  selectedId,
}: ExerciseListParams) {
  type BodyPartEnumKeys = keyof typeof BodyPart;
  type ExerciseByBodyPart = { [key in BodyPartEnumKeys]?: [Exercise] };

  const [exercises, setExercises] = useState<Exercise[]>([]);
  const [filter, setFilter] = useState<string>("");
  const [exercisesByPart, setExercisesByPart] = useState<ExerciseByBodyPart>(
    {}
  );
  const isFocused = useIsFocused();
  const [refreshing, setRefreshing] = React.useState(false);

  const onRefresh = React.useCallback(() => {
    fetchData();
  }, []);

  async function fetchData() {
    setExercises(
      (await DataStore.query(Exercise)).sort((e1, e2) =>
        e1.name.localeCompare(e2.name)
      )
    );
    setRefreshing(false);
  }

  useEffect(() => {
    if (exercises) {
      let grouped = exercises.reduce((r, a) => {
        if (
          !filter ||
          a.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0
        ) {
          r[a.bodypart] = [...(r[a.bodypart] || []), a];
        }
        return r;
      }, {});

      setExercisesByPart(grouped);
    }
  }, [exercises, filter]);

  useEffect(() => {
    if (exercises?.length == 0) setRefreshing(true);
    fetchData();
  }, [isFocused]);

  const sections = Object.keys(exercisesByPart)
    .sort((e1, e2) => e1.localeCompare(e2))
    .map((exercises, index) => {
      return {
        title: exercises,
        data: exercisesByPart[exercises],
      };
    });

  return (
    <SectionList
      sections={sections}
      keyExtractor={(item, index) => item.id}
      renderItem={({ item: exercise }) => {
        return (
          <ListItem
            button
            key={exercise.id}
            onPress={() => onSelect(exercise.id)}
            selected={exercise.id == selectedId}
          >
            <Left>
              <Text>{exercise.name}</Text>
            </Left>
            <Right>
              <Text note>{exercise.category}</Text>
            </Right>
          </ListItem>
        );
      }}
      renderSectionHeader={({ section: { title } }) => (
        <Separator key={`separator-${title}`} bordered>
          <Text>{title}</Text>
        </Separator>
      )}
      refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
      }
      ListHeaderComponent={
        <View
          style={{
            borderRadius: 10,
            backgroundColor: "#aaa",
            margin: 3,
            flexDirection: "row",
          }}
        >
          <Icon name="search" style={{ padding: 10, fontSize: 14 }} />
          <Input
            onChangeText={(f) => setFilter(f)}
            placeholder="Search"
            style={{ fontSize: 12, padding: 0, margin: 0, height: 35 }}
          />
        </View>
      }
    />
  );
}
