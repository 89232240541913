import { CognitoUser, CognitoUserAttribute } from "amazon-cognito-identity-js";
import { Auth, DataStore, Hub } from "aws-amplify";
import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { Profile } from "../models";

interface UserInfo {
  userId: string;
  userName: string;
  email: string;
  isAdmin: boolean;
  isExpert: boolean;
  profile: Profile;
  groups: String[];
  updateProfile(newProfile: Profile): Promise<Profile>;
}

export default function useUserInfo() : [UserInfo] {
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [loaded, setLoaded] = useState(false);

  async function updateProfile(newProfile: Profile): Promise<Profile> {
    const savedProfile = await DataStore.save(newProfile);
    await fetchData();
    return savedProfile;
  }

  async function fetchData() {
    const _user: CognitoUser = await Auth.currentAuthenticatedUser();
    const _userAttributes = await Auth.userAttributes(_user);
    const _userSession = await Auth.currentSession();
    const sessionIdInfo: Object = jwtDecode(_userSession.getIdToken().jwtToken);
    const profileQuery = await DataStore.query(Profile, (p) =>
      p.userID("eq", _user.getUsername())
    );

    let profile;
    if (profileQuery.length > 0) {
      profile = profileQuery[0];
    } else {
      profile = new Profile({
        userID: _user.getUsername()
      })
    }

    const groups = sessionIdInfo["cognito:groups"];
    const isAdmin = groups?.indexOf("admin") >= 0;
    const isExpert = groups?.indexOf("experts") >= 0;

    const userInfo : UserInfo = {
      userId: _user.getUsername(),
      userName: _user.getUsername(),
      email: _userAttributes.find((u) => u.getName() == "email")?.getValue()||"",
      groups: groups,
      isAdmin: isAdmin,
      isExpert: isExpert,
      profile: profile,
      updateProfile: updateProfile,
    };

    setUserInfo(userInfo);
    setLoaded(true);
  }

  useEffect(() => {
    const listener = (event) => {
      console.log(event);
      setLoaded(false);
    };
    Hub.listen("DataStore", listener);

    return function cleanup() {
      Hub.remove("DataStore", listener);
    };
  }, []);  

  useEffect(() => {
      if (!loaded) fetchData();
  }, [loaded]);

  return [userInfo];
}
