import React from "react";
import { Col, Icon, Row, Text, View } from "native-base";
import useTheme from "../../../util/useTheme";
import Styles from "../../../Styles";

export function WorkoutSetHeaders() {
  return (
    <Row style={Styles.WorkoutSetHeaderRow}>
      <Col size={1}>
        <Text style={Styles.WorkoutSetHeaderText}>Set</Text>
      </Col>
      <Col size={3}>
        <Text style={Styles.WorkoutSetHeaderText}>Reps</Text>
      </Col>
      <Col size={3}>
        <Text style={Styles.WorkoutSetHeaderText}>Weight</Text>
      </Col>
      <Col size={1}>
        <Icon style={Styles.WorkoutSetHeaderIcon} type="AntDesign" name="check" />
      </Col>
    </Row>
  );
}
