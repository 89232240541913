/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import React from "react";
import { View } from "react-native";
import { Auth, I18n, Logger } from "aws-amplify";
import AuthPiece, { IAuthPieceProps, IAuthPieceState } from "./AuthPiece";
import {
  Body,
  Button,
  Container,
  Content,
  Footer,
  Form,
  Header,
  Icon,
  Left,
  Text,
} from "native-base";
import FormField from "../components/base/FormField";
import ForceButton from "../components/base/ForceButton";

const logger = new Logger("ConfirmSignUp");

interface IConfirmSignUpProps extends IAuthPieceProps {}

interface IConfirmSignUpState extends IAuthPieceState {
  code: string | null;
}

export default class ConfirmSignUp extends AuthPiece<
  IConfirmSignUpProps,
  IConfirmSignUpState
> {
  constructor(props: IConfirmSignUpProps) {
    super(props);

    this._validAuthStates = ["confirmSignUp"];
    this.state = {
      username: null,
      code: null,
      error: null,
    };

    this.confirm = this.confirm.bind(this);
    this.resend = this.resend.bind(this);
  }

  confirm() {
    const { code } = this.state;
    const username = this.getUsernameFromInput();
    logger.debug("Confirm Sign Up for " + username);
    Auth.confirmSignUp(username, code)
      .then((data) => this.changeState("signedUp"))
      .catch((err) => this.error(err));
  }

  resend() {
    const username = this.getUsernameFromInput();
    logger.debug("Resend Sign Up for " + username);
    Auth.resendSignUp(username)
      .then(() => logger.debug("code sent"))
      .catch((err) => this.error(err));
  }

  static getDerivedStateFromProps(props, state) {
    const username = props.authData;

    if (username && !state.username) {
      return { username };
    }

    return null;
  }

  showComponent() {
    const username = this.getUsernameFromInput();
    return (
      <Container>
        <Header>
          <Left>
            <Button transparent onPress={() => this.changeState("signIn")}>
              <Icon name="ios-arrow-back" />
            </Button>
          </Left>
          <Body>
            <Text>{I18n.get("Confirm Sign Up")}</Text>
          </Body>
        </Header>
        <Content>
          <Form>
            {this.renderUsernameField()}
            <FormField
              onChangeText={(text) => this.setState({ code: text })}
              label={I18n.get("Confirmation Code")}
              placeholder={I18n.get("Enter your confirmation code")}
              required={true}
              value={this.state.code}
            />
          </Form>
          <Content padder>
            <ForceButton
              block
              onPress={this.confirm}
              disabled={!username || !this.state.code}
              text={I18n.get("Confirm")}
            />
          </Content>
        </Content>
        <Footer>
          <Button onPress={this.resend}>
            <Text>{I18n.get("Resend code")}</Text>
          </Button>
          <Button>
            <Text>{I18n.get("Back to Sign In")}</Text>
          </Button>
        </Footer>
      </Container>
    );
  }
}
