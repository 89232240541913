import { Content, Input, Picker, Textarea } from "native-base";
import React, { useEffect, useState } from "react";
import { Dimensions } from "react-native";
import { BodyPart, Category, Exercise } from "../../models";

interface Params {
    exercise: Exercise
    setExercise(exercise: Exercise): any
}

export default function ExerciseDetailTab1({exercise, setExercise} : Params) {
    function setBodyPartValue(value: BodyPart) {
        const newExercise = Exercise.copyOf(exercise, (u) => {
          u.bodypart = value;
        });
        setExercise(newExercise);
      }
    
      function setCategoryValue(value: Category) {
        const newExercise = Exercise.copyOf(exercise, (u) => {
          u.category = value;
        });
        setExercise(newExercise);
        console.log(exercise);
      }
    
      function setExerciseName(value: string) {
        const newExercise = Exercise.copyOf(exercise, (u) => {
          u.name = value;
        });
        setExercise(newExercise);
        console.log(exercise);
      }
    
      function setDescription(value: string) {
        const newExercise = Exercise.copyOf(exercise, (u) => {
          u.description = value;
        });
        setExercise(newExercise);
        console.log(exercise);
      }

    return (          
        <Content
        padder
        style={{ height: Dimensions.get("window").height - 100 }}
      >
        <Input
          placeholder="Exercise name"
          style={{ fontSize: 24, fontFamily: "Oswald_400Regular" }}
          onChangeText={setExerciseName}
          value={exercise.name}
        />
        <Textarea
          bordered
          rowSpan={8}
          onChangeText={setDescription}
          value={exercise.description}
        />

        <Picker
          selectedValue={exercise.bodypart}
          onValueChange={setBodyPartValue}
        >
          {Object.keys(BodyPart).map((part) => (
            <Picker.Item label={part} value={part} />
          ))}
        </Picker>
        <Picker
          selectedValue={exercise.category}
          onValueChange={setCategoryValue}
        >
          {Object.keys(Category).map((category) => (
            <Picker.Item label={category} value={category} />
          ))}
        </Picker>
      </Content>
    );
}