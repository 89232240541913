import {
  Body,
  Button,
  Content,
  Icon,
  Input,
  Item,
  Right,
  Text,
  Textarea,
  View,
} from "native-base";
import React, { useEffect, useState } from "react";
import { StyleProp, TextInputProps, TextStyle } from "react-native";

interface Params extends TextInputProps {
  text: string | undefined;
  placeholder?: string;
  large?: boolean;
  onChangeText?(newText: String): void;
  textStyle?: StyleProp<TextStyle>
}

export default function EditableText(params: Params) {
  const [editMode, setEditMode] = useState(false);

  const [text, setText] = useState("");

  function onSave() {
    if (params.onChangeText) {
      params.onChangeText(text);
    }
    setEditMode(false);
  }

  useEffect(() => {
    if (editMode) setText(params.text||"");
  }, [editMode]);

  return (
    <View>
      {editMode ? (
        <View
          style={{ borderWidth: 0.25, borderRadius: 3, flexDirection: "row", flex: 1, width: '100%' }}
        >
          <Input style={{backgroundColor: '#fff'}} value={text} onChangeText={setText} />
          <Button
            small
            rounded
            onPress={onSave}
            style={{ marginTop: 10, backgroundColor: "green" }}
          >
            <Icon type="AntDesign" name="check" style={{ fontSize: 10 }} />
          </Button>
          <Button
            small
            rounded
            style={{ marginTop: 10, marginRight: 10, backgroundColor: "red" }}
            onPress={() => setEditMode(false)}
          >
            <Icon
              type="MaterialCommunityIcons"
              name="cancel"
              style={{ fontSize: 10 }}
            />
          </Button>
        </View>
      ) : (
        <Text style={{...params.textStyle, fontSize: params.large ? 32 : 15}}
          onPress={() => {
            setEditMode(true);
          }}
        >
          {params.text || params.placeholder}
        </Text>
      )}
    </View>
  );
}
