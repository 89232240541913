import React, { useEffect, useState } from "react";

import {
  Button,
  Body,
  Spinner,
  Header,
  Title,
  Right,
  Icon,
  Left,
  Content,
  Container,
  Footer,
  Text,
} from "native-base";

import { DataStore, Predicates } from "@aws-amplify/datastore";
import { StackScreenProps } from "@react-navigation/stack";
import { TrainerParamList } from "../../../types";
import { TrainerFeed } from "../../models";
import TrainerFeedCard from "../../components/trainer/TrainerFeedCard";
import useFocused from "../../util/useFocused";
import { Auth } from "aws-amplify";
import { LinearGradient } from "expo-linear-gradient";
import useUserInfo from "../../util/useUserInfo";
import useTheme from "../../util/useTheme";
import ForceHeader from "../../components/base/ForceHeader";
import useDevMode from "../../util/useDevMode";

export default function TrainerFeedScreen({
  navigation,
}: StackScreenProps<TrainerParamList, "Trainer">) {
  const [feed, setFeed] = useState(new Array<TrainerFeed>());
  const [loading, setLoading] = useState(true);
  const [currentUserId, setCurrentUserId] = useState("");
  const theme = useTheme();
  const devMode = useDevMode();

  useFocused(() => {
    if (feed.length === 0) fetchData();
  });

  const [userInfo] = useUserInfo();

  React.useLayoutEffect(() => {
    navigation.setOptions({
      header: () => (
        <ForceHeader noLeft>
          <Left />
          <Body>
            <Title>Trainer Feed</Title>
          </Body>
          <Right>
            <Button transparent onPress={() => fetchData()}>
              <Icon type="AntDesign" name="reload1" />
            </Button>
            {userInfo?.isExpert ? (
              <Button
                transparent
                onPress={() => navigation.navigate("TrainerPublishContent")}
              >
                <Icon type="AntDesign" name="plus" />
              </Button>
            ) : undefined}
          </Right>
        </ForceHeader>
      ),
    });
  });

  async function fetchData() {
    try {
      setLoading(true);

      const user = await Auth.currentUserInfo();
      setCurrentUserId(user.username);

      //c => c.published("eq", true)
      const feed = await DataStore.query(TrainerFeed, Predicates.ALL, {
        page: 0,
        limit: 100,
      });
      setFeed(
        feed.sort((f1, f2) =>
          (f2.publishedDate || "").localeCompare(f1.publishedDate || "")
        )
      );

      setLoading(false);
    } catch (err) {
      console.log("error fetching workouts", err);
    }
  }

  async function removeFeedItem(feedItemId: string) {
    setLoading(true);
    await DataStore.delete(TrainerFeed, feedItemId);
    await fetchData();
  }

  return (
    <Container style={{ backgroundColor: theme.toolbarDefaultBg }}>
      <Content padder>
        {devMode && <Text style={{ color: "white" }}>{feed.length}</Text>}
        {loading ? (
          <Spinner />
        ) : (
          feed.map((feedItem, index) => (
            <TrainerFeedCard
              feedItem={feedItem}
              key={feedItem.id}
              isTrainer={currentUserId === feedItem.author}
              onRemoveFeedItem={() => removeFeedItem(feedItem.id)}
            />
          ))
        )}
      </Content>
    </Container>
  );
}
