import { I18n } from "aws-amplify";
import { Input, Item, Label, Picker, Text, View } from "native-base";
import React, { Component } from "react";

import countryDialCodes from '../../auth/CountryDialCodes';

interface IPhoneProps {
	defaultDialCode?: string;
	label: string;
	onChangeText: (phoneNumber: string) => void;
	required?: boolean;
	value?: string;
}

interface IPhoneState {
	dialCode: string;
	phone: string;
}


export default class PhoneField extends Component<IPhoneProps, IPhoneState> {
	constructor(props: IPhoneProps) {
		super(props);

		this.state = {
			dialCode: this.props.defaultDialCode || '+1',
			phone: '',
		};
	}

	onChangeText() {
		const { dialCode, phone } = this.state;
		const cleanedPhone = phone.replace(/[^0-9.]/g, '') || '';
		const phoneNumber = cleanedPhone === '' ? '' : `${dialCode}${cleanedPhone}`;
		this.props.onChangeText(phoneNumber);
	}

	render() {
		const { label, required, value } = this.props;
		const { dialCode } = this.state;

		const phoneValue = value ? value.replace(dialCode, '') : undefined;

		return (
			<Item stackedLabel>
				<Label>
					{label} {required ? '*' : ''}
				</Label>
				<View style={{alignContent: "space-between", flexDirection: "row"}}>
					<Picker
						selectedValue={this.state.dialCode}
						onValueChange={dialCode => {
							this.setState({ dialCode }, () => {
								this.onChangeText();
							});
						}}
					>
						{countryDialCodes.map(dialCode => (
							<Picker.Item key={dialCode} value={dialCode} label={dialCode} />
						))}
					</Picker>
					<Input
						autoCapitalize="none"
						autoCorrect={false}
						keyboardType="phone-pad"
						{...this.props}
						value={phoneValue}
						onChangeText={phone => {
							this.setState({ phone }, () => {
								this.onChangeText();
							});
						}}
					/>
				</View>
			</Item>
		);
	}
}