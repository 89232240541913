/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:081a448b-bf32-42f5-b574-373699c07ed3",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_TvumIM3JM",
    "aws_user_pools_web_client_id": "7kbkcaln7l0609sbq9vvm0dg8k",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://xnobv35j6fa6rkbpdmpizqcfoe.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://4hsp8d3tdd.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "forceamplify0a087bad27dc4824818f072dfb5eb9ca164851-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
