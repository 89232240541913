import { StackScreenProps } from "@react-navigation/stack";
import { Body, Button, Container, Icon, Left, Right, Title } from "native-base";
import React from "react";
import { ExerciseManagementParamList } from "../../types";
import ExerciseList from "../components/base/ExerciseList";
import ForceHeader from "../components/base/ForceHeader";

export default function ExerciseManagerScreen({
  navigation,
}: StackScreenProps<ExerciseManagementParamList, "ExerciseManager">) {
  React.useLayoutEffect(() => {
    navigation.setOptions({
      header: () => (
        <ForceHeader noLeft>
          <Left />
          <Body>
            <Title>Exercises</Title>
          </Body>
          <Right>
            <Button
              transparent
              onPress={() => navigation.navigate("ExerciseEditor", { id: "" })}
            >
              <Icon type="AntDesign" name="plus" />
            </Button>
          </Right>
        </ForceHeader>
      ),
    });
  }, [navigation]);

  function gotoSelectedExercise(exerciseId: string) {
    navigation.navigate("ExerciseDetail", { id: exerciseId });
  }

  return (
    <Container>
      <ExerciseList onSelect={gotoSelectedExercise} />
    </Container>
  );
}
