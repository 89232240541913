import { useFocusEffect } from "@react-navigation/native";
import { StackScreenProps } from "@react-navigation/stack";
import Amplify, { DataStore } from "aws-amplify";
import {
  Body,
  Button,
  Col,
  Container,
  Content,
  H1,
  H3,
  Header,
  Icon,
  Left,
  Right,
  Row,
  Text,
  Title,
  View,
} from "native-base";
import React, { createRef, useEffect, useRef, useState } from "react";
import { ExerciseManagementParamList } from "../../types";
import AmplifyImage from "../components/base/AmplifyImage";
import ForceHeader from "../components/base/ForceHeader";
import { BodyPart, Category, Exercise } from "../models";
import useUserInfo from "../util/useUserInfo";
import Carousel, { Pagination } from "react-native-snap-carousel";
import useTheme from "../util/useTheme";

export default function ExerciseManagerScreen({
  navigation,
  route,
}: StackScreenProps<ExerciseManagementParamList, "ExerciseDetail">) {
  const { id } = route.params;
  const [userInfo] = useUserInfo();
  const [exercise, setExercise] = useState<Exercise>(
    new Exercise({
      name: "",
      description: "",
      bodypart: BodyPart.CHEST,
      category: Category.DUMBBELL,
      instructions: [""],
    })
  );
  const carouselRef = createRef();
  const [slide, setSlide] = useState();
  const theme = useTheme();


  async function saveExercise() {
    console.log(exercise);
    await DataStore.save(exercise);
    navigation.goBack();
  }

  React.useLayoutEffect(() => {
    navigation.setOptions({
      header: () => (
        <ForceHeader hasTabs noShadow>
          <Left>
            <Button transparent onPress={() => navigation.goBack()}>
              <Icon name="ios-arrow-back" />
            </Button>
          </Left>
          <Right>
            {userInfo?.isAdmin ? (
              <Button
                transparent
                onPress={() =>
                  navigation.navigate("ExerciseEditor", { id: exercise.id })
                }
              >
                <Icon type="Entypo" name="pencil" />
              </Button>
            ) : undefined}
          </Right>
        </ForceHeader>
      ),
    });
  });

  useFocusEffect(() => {
    async function fetchData() {
      let exercise;
      if (id !== "") {
        exercise = await DataStore.query(Exercise, id);
        if (exercise !== undefined) {
          setExercise(exercise);
        }
      }
    }

    fetchData();
  });

  return (
    <Container>
      <ForceHeader style={{marginTop: 20}}>
        <Body>
          <Text style={{fontSize: 32}}>{exercise.name}</Text>
        </Body>
      </ForceHeader>
      <Content>
        <View style={{ flexDirection: "row", padding: 10 }}>
          <Button dark style={{ height: 30, marginRight: 10 }}>
            <Text style={{ fontSize: 10 }}>{exercise.bodypart}</Text>
          </Button>
          <Button dark style={{ height: 30 }}>
            <Text style={{ padding: 0, fontSize: 10 }}>
              {exercise.category}
            </Text>
          </Button>
        </View>
        <View style={{ flex: 1 }}>
          <Carousel
            data={exercise.images||[]}
            renderItem={(item) => (
              <View
                key={item.item}
                style={{
                  overflow: "hidden",
                  borderWidth: 0,
                  borderRadius: 10,
                  alignContent: "center"
                }}
              >
                <AmplifyImage
                  uri={item.item}
                  width={300}
                  height={180}
                  style={{ borderRadius: 10 }}
                />
              </View>
            )}
            sliderWidth={400}
            itemWidth={300}
            onSnapToItem={(index) => setSlide(index)}
          />
          <Pagination
            dotsLength={exercise.images?.length || 0}
            activeDotIndex={slide || 0}
            dotStyle={{
              width: 10,
              height: 10,
              borderRadius: 5,
              marginHorizontal: 8,
              backgroundColor: "rgba(255, 255, 255, 0.92)",
            }}
            inactiveDotStyle={
              {
                // Define styles for inactive dots here
              }
            }
            inactiveDotOpacity={0.4}
            inactiveDotScale={0.6}
          />
        </View>
        <Content padder>
          <H3>Instructions</H3>
          {exercise.instructions?.map((e, i) => (
            <Row style={{ paddingBottom: 20 }}>
              <Col size={1}>
                <Text>{i + 1}.</Text>
              </Col>
              <Col size={5}>
                <Text>{e}</Text>
              </Col>
            </Row>
          ))}
        </Content>
      </Content>
    </Container>
  );
}
