import { Button, Text } from 'native-base';
import React from 'react';

interface Props {
    text: string
    onPress(): void
    disabled?: boolean
}

export default function LinkButton(props: Props) {
    return (
        <Button transparent disabled={props.disabled} onPress={props.onPress}>
            <Text>{props.text}</Text>
        </Button>
    )
}